@import "style-guide";

.section {
  padding-bottom: 8*$u;

  &--isLast {
    padding-bottom: 0;
  }

  &__title {
    @include secondary-font;
    @include Title-Widget-font;

    margin-bottom: 6*$u;
  }
}

.field {
  &--formal {
    width: 33%;
  }

  &--first-name {
    width: 33%;
  }

  &--contact {
    width: 50%;
  }

  &--last-name {
    width: 33%;
  }

  &--link {
    width: 50%;
  }

  &--birthday {
    width: 50%;
  }

  &--country {
    width: 50%;
  }

  &--language {
    width: 50%;
  }
}

.button {
  width: 100%;
}
