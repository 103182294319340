@import "style-guide";

.table {
  &__header {
    @include Table-Header-font;
    @include Table-Header-middle-column;

    text-align: left;

    &--origin {
      @include Table-Header-left-column;
    }

    &--status {
      @include Table-Header-right-column;
    }
  }

  &__column {
    @include Table-Row-middle-column;

    &--origin {
      @include Table-Row-left-column;
    }

    &--status {
      @include Table-Row-right-column;
    }
  }
}
