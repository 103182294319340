@import "style-guide";

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: $minHeaderHeight;
}

.left-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
}

.right-node {
  margin-left: 2*$u;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.left-node {
  margin-right: 2*$u;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.title {
  @include header-title-font;

  margin-right: 4 * $u;
}

.after-title {

}
