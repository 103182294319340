@import "style-guide";

.option {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.avatar {
  margin-right: 2*$u;
}

.name {
  @include Avatar-User-font;

  color: $charcoal;
}
