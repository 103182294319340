@import "style-guide";

.filters-trigger {
  margin-right: 2*$u;
}

.sort-trigger {
  margin-right: 2*$u;
}

.source-tabs {
  margin-right: 4*$u;
}
