@import "style-guide";

.field {
  &--time-picker {
    padding-bottom: 4*$u;
  }

  &--comment {
    padding-bottom: 3*$u;
  }
}

.actions {
  width: 100%;
  margin: 5*$u 0 5*$u 2*$u;
  display: flex;
}

.footer {
  display: flex;
}

.action {
  &--cancel {
    width: 50%;
    margin-left: $u;
  }

  &--send {
    width: 50%;
    margin-right: $u;
  }
}

.time-window-pill-label {
  margin-left: $u;
}
