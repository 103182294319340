@import "style-guide";

.title {
  @include Auth-Title-font;

  text-align: center;
  margin-bottom: 6*$u;
}

.fields {

}

.content {
  text-align: center;
}

.error {
  text-align: center;
  color: $tomato-red;
}
