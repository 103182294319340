@import "style-guide";

.table {
  &__header {
    padding: 0 3*$u;

    &--name {
      padding: 0 3*$u 0 5*$u;
      flex-grow: 1;
    }

    &--status {
      @include table-column-auto-adjust-width;
    }

    &--expire-date {
      text-align: center;
    }
  }

  &__column {
    padding: 3*$u;

    &--name {
      padding: 0 3*$u 0 5*$u;
      flex-grow: 1;
    }

    &--status {
      @include table-column-auto-adjust-width;
    }

    &--expire-date {
      text-align: center;
    }

    &--reviewed,
    &--uploaded {
      max-width: 50 * $u;
    }

    &--actions {
      @include table-column-auto-adjust-width;

      padding: 0 5*$u 0 3*$u;
    }
  }
}
