@import "style-guide";

$assetBackgroundColor: $charcoal;
$assetTriangleSize: $u;
$defaultPadding: 2*$u;

.map-tooltip {
  $class: &;

  position: relative;
  background-color: $assetBackgroundColor;
  border-radius: 3*$u;
  margin-bottom: $assetTriangleSize;

  &--hasPaddings {
    padding: $defaultPadding;
  }

  &--hasBorder {
    border: $defaultMapPinBorderWidth solid white;

    &#{$class}--hasPaddings {
      padding: $defaultPadding - $defaultMapPinBorderWidth;
    }
  }

  &--hasArrow {
    &::after {
      content: "";
      position: absolute;
      bottom: -2 * $assetTriangleSize;
      width: 0;
      height: 0;
      border-left: $assetTriangleSize solid transparent;
      border-bottom: $assetTriangleSize solid transparent;
      border-right: $assetTriangleSize solid transparent;
      border-top: $assetTriangleSize solid $assetBackgroundColor;
      z-index: $z-index-price-matrix-tooltip;
      transform: translateX(-50%);
      left: 4*$u;
    }
  }

  &:hover {
    z-index: $z-index-map-pin-hover;
  }

  &--theme-charcoal {
    // default theme
  }

  &--theme-light {
    background-color: $light;

    &::after {
      border-top-color: $light;
    }
  }

  &--theme-gray {
    background-color: $gray;

    &::after {
      border-top-color: $gray;
    }
  }

  &--theme-brand-dark {
    background-color: $brand-dark;

    &::after {
      border-top-color: $brand-dark;
    }
  }

  &--theme-slate {
    background-color: $slate;

    &::after {
      border-top-color: $slate;
    }
  }

  &--theme-tomato-red {
    background-color: $tomato-red;

    &::after {
      border-top-color: $tomato-red;
    }
  }
}
