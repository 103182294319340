@import "style-guide";

.map-loader {
  &__container {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 4*$u);
    z-index: $z-index-map-tooltip;
  }
}
