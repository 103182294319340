@import "style-guide";

.paranja {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $z-index-form-paranja;
  background-color: $white;
  opacity: 0.8;
}
