@import "style-guide";

$directionWidth: 4*$u;

.route {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &__location {
    width: calc(50% - #{$directionWidth / 2});
    height: 100%;
    border-radius: 4px;
    background-color: $light-30-percent;
  }

  &__direction {
    width: $directionWidth;
    margin: $u;
  }
}

.location {
  padding: 4*$u 4*$u 3*$u 4*$u;

  &__locality {
    @include secondary-font;
    @include Title-Widget-font;
  }

  &__country {
    font-size: 14px;
    font-weight: 500;
    color: $charcoal;
    line-height: 1.43;
    margin-bottom: $u;
  }

  &__flag {
    margin-right: $u;
    vertical-align: top;
  }

  &__time-window {
    font-size: 11px;
    font-weight: bold;
    line-height: 1.82;
    color: $charcoal;
  }
}

.skeleton {
  display: inline-block;
  padding: $u 0;
  height: 2*$u;
  background-color: $light;
  border-radius: $u;
}
