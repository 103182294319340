@import "style-guide";

$iconPadding: $u;

.wrap {
  display: flex;
  align-items: center;

  &--isInline {
    display: inline-flex;
    vertical-align: middle;
  }

  &--isRightIcon {
    .icon {
      padding-left: $iconPadding;
      order: 2;
    }

    .label {
      order: 1;
    }
  }
}

.icon {
  position: relative;
  margin-right: $iconPadding;

  &--withVerticalPaddings {
    margin-top: $u;
    margin-bottom: $u;
  }
}

.label {
  @include Status-Regular-font;
}
