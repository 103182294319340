@import "style-guide";

.wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.text {
  margin-right: $u;
  font-size: 11px;
  line-height: 4*$u;
}

.broker {
  font-size: 14px;
  line-height: 4*$u;
}
