@import "style-guide";

.button-group-item {
  padding: $u 2*$u;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  font-size: 11px;
  font-weight: bold;
  line-height: 1.82;
  color: $charcoal;
  text-transform: uppercase;
  white-space: nowrap;

  &--isHovered {
    background-color: $inputHoverBackgroundColor;
  }

  &--isActive {
    background-color: $inputFocusBackgroundColor;
  }
}
