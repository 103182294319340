@import "style-guide";

.wrap {
  position: relative;
  padding-top: 4*$u;
}

.alert {
  margin-bottom: 6*$u;
}

.document-table-wrap {
  position: relative;
  min-height: 100*$u;
}
