@import "style-guide";

.message {

}

.icon {
  margin-bottom: 2*$u;
  height: 27*$u;
  background-repeat: no-repeat;
  background-position: center;

  &--done {
    background-image: url("./icons/illustration-done.svg");
  }

  &--empty {
    background-image: url("./icons/illustration-empty.svg");
  }
}

.title {
  @include SideBar-Message-title-font;

  text-align: center;
  margin-bottom: 4*$u;
}

.description {
  @include SideBar-Message-description-font;

  text-align: center;
  margin-bottom: 8*$u;
}
