@import "style-guide";

.notification {
  border-radius: 8px;
  padding: 0 2*$u;
  min-width: 6*$u;
  text-align: center;

  @include Notification-Number-font;
  @include clear-bold-font-for-avoid-jumping;

  &--theme {
    &-slate {
      color: $white;
      background-color: $slate;
    }

    &-charcoal {
      color: $white;
      background-color: $charcoal;
    }

    &-orange {
      color: $white;
      background-color: $orange;
    }

    &-green {
      color: $white;
      background-color: $brand-accent;
    }

    &-red {
      color: $white;
      background-color: $tomato-red;
    }
  }
}
