@import "style-guide";

.flag-wrap {
  padding: $u;
  border-radius: 4px;

  &--is-show {
    background-color: $transparent-light-50-percent;
  }
}

.flag {
  vertical-align: top;
}
