@import "style-guide";

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14*$u;
}

.skeleton {
  &__box {
    @include base-skeletons;

    &--table-head {
      height: 4*$u;
      margin-bottom: 2*$u;
    }

    &--table-row {
      height: 8*$u;
      margin-bottom: 4*$u;
    }
  }
}
