@import "style-guide";

.table {
  &__header {
    @include Table-Header-font;
    @include Table-Header-middle-column;

    text-align: left;

    &--checkbox {
      @include Table-Header-left-checkbox-column;
      @include table-column-auto-adjust-width;
    }

    &--trailer-variant {
      text-align: center;
    }

    &--controls {
      @include Table-Header-right-column;
      @include table-column-auto-adjust-width;
    }
  }

  &__column {
    @include Table-Row-middle-column;

    &--checkbox {
      @include Table-Row-left-checkbox-column;
      @include table-column-auto-adjust-width;
    }

    &--trailer-variant {
      padding-top: $u;
      padding-bottom: $u;
      width: 20*$u;
    }

    &--updated {
      max-width: 50 * $u;
    }

    &--controls {
      @include Table-Row-right-controls-column;
      @include table-column-auto-adjust-width;
    }
  }
}
