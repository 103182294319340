@import "style-guide";

.side-bar {
  position: absolute;
  top: 0;
  width: 100%;
  max-width: $defaultSidebarWidth;
  height: 100%;
  background-color: white;
  z-index: $z-index-sidebar;

  /* transition: transform $defaultAnimationDuration; */

  &--position-left {
    left: 0;
    transform: translateX(-1 * $defaultSidebarWidth);

    &.side-bar--isShow {
      @include sidebar-shadow;
    }
  }

  &--position-right {
    right: 0;
    transform: translateX($defaultSidebarWidth);

    &.side-bar--isShow {
      @include sidebar-shadow;
    }
  }

  &--isShow {
    transform: translateX(0);
  }

  &--isNarrow {
    max-width: $narrowSidebarWidth;
  }
}

.content {
  position: relative;
  height: 100%;
  width: 100%;
}
