@import "style-guide";

.form {
  height: 100%;
}

.actions {
  display: flex;
  flex-direction: row;

  &__action {
    width: 100%;

    &--isDiscard {
      margin-right: 2*$u;
    }
  }
}

.section {
  &__title {
    @include Title-Widget-font;

    margin-bottom: 6*$u;
  }

  &__block {
    padding-bottom: 8*$u;
  }
}

.field {
  &--formal {
    width: 33%;
  }

  &--first-name {
    width: 33%;
  }

  &--last-name {
    width: 33%;
  }

  &--desktop-phone {
    width: 50%;
  }

  &--mobile-phone {
    width: 50%;
  }

  &--fax-number {
    width: 50%;
  }

  &--email {
    width: 50%;
  }

  &--birthday {
    width: 50%;
  }

  &--country {
    width: 50%;
  }

  &--language {
    width: 50%;
  }
}

.fields-group {
  display: flex;

  &__field {
    margin-right: 3*$u;
    margin-left: 3*$u;

    &:last-child {
      margin-right: 0;
    }

    &:first-child {
      margin-left: 0;
    }

    &--three {
      width: 33%;
    }

    &--two {
      width: 50%;
    }
  }
}

.checkbox {
  &__label {
    @include Input-Value-Normal-font;
  }
}

.radio {
  padding-bottom: 3*$u;
}

.media {
  display: flex;
  flex-direction: row;

  &__input {
    margin-right: 2*$u;
  }

  &__icon {
    min-width: 8*$u;
  }
}
