@import "style-guide";

.link {
  cursor: pointer;
  text-decoration: underline;
  user-select: none;

  &:hover {
    text-decoration: none;
  }

  &--size {
    &-extra-small {
      font-size: 10px;
      letter-spacing: 0.4px;
    }

    &-pre-extra-small {
      font-size: 11px;
      line-height: 20px;
    }

    &-small {
      font-size: 12px;
    }

    &-medium {
      font-size: 14px;
    }

    &-large {
      font-size: 18px;
    }
  }

  &--theme-charcoal {
    color: $charcoal;
  }

  &--theme-bold-charcoal {
    color: $charcoal;
    font-weight: bold;
  }

  &--theme-bold-azul {
    color: $azul;
    font-weight: bold;
  }

  &--theme-bold-brand-dark {
    color: $brand-dark;
    font-weight: bold;
  }

  &--theme-bold-brand-accent {
    color: $brand-accent;
    font-weight: bold;
  }

  &--theme-bold-white {
    color: $white;
    font-weight: bold;
  }

  &--theme-bold-tomato-red {
    color: $tomato-red;
    font-weight: bold;
  }

  &--theme-bold-orange {
    color: $orange;
    font-weight: bold;
  }

  &--uppercase {
    text-transform: uppercase;
  }
}
