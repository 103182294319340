/* Start: table headers mixins */

@mixin Table-Header-middle-column {
  padding: 0 3*$u 2*$u 3*$u;
}

@mixin Table-Header-left-column {
  padding: 0 3*$u 2*$u 5*$u;
}

@mixin Table-Header-left-checkbox-column {
  padding: 0 $u 2*$u 4*$u;
}

@mixin Table-Header-right-column {
  padding: 0 5*$u 2*$u 3*$u;
}

/* End: table headers mixins */

/* Start: table rows mixins */

@mixin Table-Row-middle-column {
  padding: 3*$u;
}

@mixin Table-Nowrap-Eclipse {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  /* start fix: overflow ellipsis */
  display: block;

  /* end fix: overflow ellipsis */
}

@mixin Table-Row-left-checkbox-column {
  padding: 3*$u $u 3*$u 4*$u;
}

@mixin Table-Row-left-column {
  padding: 3*$u 3*$u 3*$u 5*$u;
}

@mixin Table-Row-right-column {
  padding: 3*$u 5*$u 3*$u 3*$u;
}

@mixin Table-Row-right-controls-column {
  padding: $u $u $u $u;
}

@mixin Table-Row-right-control-column {
  padding: 3*$u;
}

/* End: table rows mixins */
