@import "style-guide";
@import "auth";

.fields {

}

.field {
  margin-bottom: 5*$u;

  &-label {
    font-size: 14px;
    letter-spacing: normal;
    color: $charcoal;
    margin-bottom: $u;
  }
}

.submit {
  @include submit-button;
}

.footer-links {
  @include footer-links;

  &__link {
    @include footer-link;
  }
}

.notification {
  position: fixed;
  right: 10*$u;
  top: 10*$u;
  width: 70*$u;
  animation: opacityAppear $defaultAnimationDuration;

  @media only screen and (max-width: 600px) {
    right: 4*$u;
    top: 4*$u;
    width: 50*$u;
  }
}

@keyframes opacityAppear {
  0% {
    transform: translateX(80*$u);
  }

  100% {
    transform: translateX(0);
  }
}

.input {
  padding-bottom: 2*$u;
}
