@import "style-guide";

.icon {
  margin-right: 2*$u;
}

.label {
  @include Input-Value-Placeholder-font;
}

.option {
  display: flex;
  flex-direction: row;
  align-items: center;
}
