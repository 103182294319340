@import "style-guide";

.wrap {
  /* for Chrome */
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }

  /* for Firefox */
  input[type="number"] {
    appearance: textfield;
  }
}

.content {
  position: relative;
  display: flex;
}

.input {
  border: solid $inputBorderWidth transparent;
  padding: ((2*$u) - $inputBorderWidth) ((3*$u) - $inputBorderWidth);
  border-radius: $controlDefaultBorderRadius;
  background-color: $inputDefaultBackgroundColor;

  &--isEmpty {
    border-color: transparent;
  }

  &--isHovered {
    background-color: $inputHoverBackgroundColor;
  }

  &--hasIcon {
    padding-left: (8 * $u) - $inputBorderWidth;
  }

  &--isFocused {
    background-color: $inputFocusBackgroundColor;
  }

  &--hasWarning {
    border-color: $inputWarningBorderColor;
  }

  &--isChanged {
    border-color: $inputWarningBorderColor;
  }

  &--hasError {
    border-color: $inputErrorBorderColor;
  }

  &--isDisabled {
    background-color: $inputDisabledBackgroundColor;
    border-color: $inputDisabledBackgroundColor;
    cursor: not-allowed;

    &:hover {
      background-color: $inputDisabledBackgroundColor;
    }
  }
}

.input-inner {
  border: none;
  background-color: transparent;
  outline: none;
  padding: 0;
  width: 100%;

  @include base-font;
  @include Input-Value-Bold-font;

  &:placeholder {
    @include Input-Value-Placeholder-font;
  }

  &--isEmpty {
    @include Input-Value-Placeholder-font;
  }

  &--isDisabled {
    color: $inputDisabledColor;
    cursor: not-allowed;
  }
}

.input-wrap {
  flex-grow: 1;
  min-width: 0;

  &--withValueControl {
    margin-right: $inputBorderWidth;
  }
}

.icon {
  position: absolute;
  top: 2*$u;
  left: 2*$u + $inputBorderWidth;
}

.units {
  position: absolute;
  top: 2*$u;
  right: 7*$u;
  pointer-events: none;

  @include Input-Units-font;

  &--isEmpty {
    @include Input-Value-Placeholder-font;
  }

  &--isDisabled {
    color: $inputDisabledColor;
  }

  &--isHideValueControl {
    right: ((3*$u) - $inputBorderWidth);
  }
}

$controlSize: 19px;

.controls {
  display: flex;
  flex-direction: column;

  &__control {
    cursor: pointer;
    width: $controlSize;
    height: $controlSize;
    user-select: none;
    background-color: $labelDefaultBackgroundColor;

    &--increment {
      border-top-right-radius: $controlDefaultBorderRadius;
      margin-bottom: $inputBorderWidth/2;
    }

    &--decrement {
      border-bottom-right-radius: $controlDefaultBorderRadius;
      margin-top: $inputBorderWidth/2;
    }

    &--isEmpty {
      @include Input-Value-Placeholder-font;
    }

    &--isHovered {
      background-color: $inputHoverBackgroundColor;
    }

    &--isFocused {
      background-color: $inputFocusBackgroundColor;
    }

    &--isDisabled {
      background-color: $light;
      cursor: not-allowed;
    }
  }
}
