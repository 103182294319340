@import "style-guide";

$borderWidth: 2px;

.list {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 80*$u;
  max-height: 80*$u;
  border: solid $borderWidth transparent;
  border-radius: 4px;

  &--hasWarning {
    border-color: $inputWarningBorderColor;
  }

  &--hasError {
    border-color: $inputErrorBorderColor;
  }
}

$optionVerticalPadding: $u;

.option {
  display: flex;
  align-items: center;
  cursor: pointer;

  @include Dropdown-ListBold-font;

  &__checkbox {
    padding: $optionVerticalPadding 0;
  }

  &__label {
    flex-grow: 1;
    margin: 0 !important; // TODO remove dashboard-ui checkbox
  }
}

.link {
  cursor: pointer;
  user-select: none;
  margin-bottom: 3px;

  &__icon {
    vertical-align: top;
    margin-left: 3px;
    margin-top: 3px;
    margin-bottom: 3px;
    margin-right: $u + 3px;
  }

  &__text {
    vertical-align: top;

    @include Input-Label-font;

    color: #4b6518;
    text-decoration: underline;
  }
}

.loader {
  &__container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
