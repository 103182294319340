@import "style-guide";

.table {
  &__header {
    @include Table-Header-font;
    @include Table-Header-middle-column;

    text-align: left;
  }

  &__column {
    &--avatar {
      padding: 4*$u 0 4*$u 4*$u;

      @include table-column-auto-adjust-width;
    }

    &--any {
      @include Table-Row-middle-column;
    }

    &--controls {
      @include table-column-auto-adjust-width;
      @include Table-Row-right-control-column;
    }
  }
}
