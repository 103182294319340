@import "style-guide";
@import "auth";

.checkbox {
  margin-bottom: 2*$u;
}

.terms {
  color: $charcoal;
}

.submit {
  @include submit-button;
}

.footer-links {
  @include footer-links;

  &__link {
    @include footer-link;
  }
}
