@import "style-guide";

$scaleHeight: 4*$u;
$height: $scaleHeight + $u;

.container {
  position: relative;
  width: 100%;
  height: $height;
  margin-bottom: 6*$u;
  user-select: none;

  // prevent margin collapse
  padding-bottom: 0.05px;

  &--isDisabled {
    opacity: 0.2;
  }
}

.selection-range {
  position: absolute;
  height: 100%;
  z-index: $z-index-range-selector-3;

  &--isDragable {
    cursor: grab;
  }

  &--isDragging {
    cursor: grabbing;
  }

  &--isDisabled {
    cursor: not-allowed;
  }

  &--isOutOffValues {
    opacity: 0;
  }
}
