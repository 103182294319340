@import "style-guide";

.wrap {
  display: flex;
}

.radio {
  position: relative;
  margin: 2px;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  border: solid 2px $light;
  background-color: $inputHoverBackgroundColor;

  &--isClickable {
    cursor: pointer;
    user-select: none;
  }

  &--isHover {
    border: solid 2px $light;
    background-color: #f4f5f8;
  }

  &--isSelected {
    border-color: #7ca829;
    background-color: #7ca829;

    &::after {
      content: " ";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 6px;
      height: 6px;
      background-color: $white;
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &--isSelected.radio--isHover {
    border-color: #759e27;
    background-color: #759e27;
  }

  &--isDisabled {
    border-color: $light;
    background-color: $light-30-percent;
    cursor: not-allowed;
  }

  &--isSelected.radio--isDisabled {
    border-color: $light;
    background-color: $light-30-percent;
    cursor: not-allowed;

    &::after {
      background-color: $gray;
    }
  }
}

.label {
  margin-left: 2 * $u;

  @include Input-Value-Normal-font;

  &--isClickable {
    cursor: pointer;
    user-select: none;
  }

  &--isDisabled {
    cursor: not-allowed;
  }

  &--hasRightIcon {
    margin-right: 2 * $u;
  }
}
