@import "style-guide";

.content {
  max-width: 68*$u;
  margin-bottom: 6*$u;
}

.method {
  word-wrap: break-word;
}

.time {
  font-size: 10px;
}

.response {
  overflow-wrap: break-word;
}
