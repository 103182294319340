@import "style-guide";

$scaleHeight: 4*$u;

.step {
  position: absolute;
  bottom: 0;
  width: 1px;
  height: $scaleHeight;
  transform: translateX(-0.5px);
  background-color: $white;
  z-index: $z-index-range-selector-2;
  opacity: 0.5;

  &--isHideStep {
    opacity: 0;
  }

  &__label {
    position: absolute;
    top: calc(100% + #{2 * $u});
    left: 0;
    text-align: center;
    transform: translateX(-50%);
    font-size: 11px;
    line-height: 20px;
    font-weight: 500;
    color: $slate;
    white-space: nowrap;

    &--isFirst {
      transform: translateX(0%);
    }

    &--isLast {
      transform: translateX(-100%);
    }
  }
}
