@import "style-guide";

.content {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  padding-left: 6 * $u;
  padding-right: 6 * $u;

  &--without-scrollbar {
    overflow-y: hidden;
  }

  &--is-wide {
    padding-left: 0;
    padding-right: 0;
  }
}
