@import "style-guide";

.alerts {
  @include alerts-trailing-bottom-margin;
}

.button {
  width: 100%;
}

.wrap {

}

.fields {
  margin-bottom: 3*$u;
}

.field {
  &--placeholder,
  &--country-code,
  &--vin,
  &--emission-standard {
    width: 50%;
  }

  &--cabin-color {
    width: 50%;
  }

  &--contract {
    width: 50%;
  }

  &--rate {
    width: 50%;
  }

  &--fuel-consumption {
    width: 50%;
  }

  &--oil-percent {
    width: 50%;
  }
}
