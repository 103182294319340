@import "style-guide";

.wrap {
}

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8*$u;

  &__title {
    @include secondary-font;
    @include Title-Widget-font;
  }
}

.field {
  margin-bottom: 2*$u;
}

.form {
  padding-bottom: 3*$u;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2*$u;
}

.sub-actions {
  width: 60%;
  display: flex;
  flex-direction: row;
  gap: 2*$u;
}

.action {
  &--clear-assets {
    width: 25%;
  }

  &--reassign-cancel {
    width: 40%;
  }

  &--reassign {
    width: 60%;
  }

  &--assign-cancel {
    width: 30%;
  }

  &--assign {
    width: 70%;
  }
}

.trailer-option {
  border-bottom: 1px solid $light;

  &:last-child {
    border-bottom: none;
  }
}

.truck-option {
  border-bottom: 1px solid $light;

  &:last-child {
    border-bottom: none;
  }
}
