@import "style-guide";

.wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.tooltip {
  margin-right: 2*$u;
}

.title {
  @include Info-table-name-normal-font;

  margin-right: 2*$u;
}

.country {
  margin-right: $u;
}
