@import "style-guide";

$lineHeight: 3*$u;

.note {
  margin-top: $u;
  line-height: $lineHeight;
  min-height: $lineHeight;

  @include Input-Note-Normal-font;

  &--error {
    @include Input-Note-Error-font;
  }

  &--warning {
    @include Input-Note-Warning-font;
  }

  &--success {
    @include Input-Note-Success-font;
  }
}
