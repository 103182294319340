@import "style-guide";

.wrap {
  display: flex;
}

.switcher {
  position: relative;
  height: 4*$u;
  width: 8*$u;
  border-radius: 2*$u;
  background-color: $light;
  transition: background-color $defaultAnimationDuration;

  &::after {
    content: " ";
    position: absolute;
    width: 16px;
    height: 16px;
    top: 2px;
    left: 2px;
    opacity: 0.75;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    background-color: #fff;
    transition: left $defaultAnimationDuration;
  }

  &--isClickable {
    cursor: pointer;
    user-select: none;
  }

  &--isHover {

  }

  &--theme-primary {
    &.switcher--isChecked {
      background-color: #7ca829;
    }

    &.switcher--isChecked.switcher--isHover {
      background-color: #759e27;
    }
  }

  &--theme-danger {
    &.switcher--isChecked {
      background-color: $orange;
    }

    &.switcher--isChecked.switcher--isHover {
      background-color: $orange;
    }
  }

  &--isChecked {
    &::after {
      left: 22px;
    }
  }

  &--isDisabled {
    opacity: 0.2;
    background-color: $light-30-percent !important;
    cursor: not-allowed;
  }

  &--isChecked.switcher--isDisabled {
    background-color: $orange !important;
  }
}

.label {
  margin-left: 2 * $u;

  @include Input-Value-Normal-font;

  &--isClickable {
    cursor: pointer;
    user-select: none;
  }

  &--hasRightIcon {
    margin-right: $u;
  }

  &--isDisabled {
    opacity: 0.2;
    cursor: not-allowed;
  }
}
