@import "style-guide";

.form {
  height: 100%;
}

.button {
  width: 100%;
}

.field {
  &--order-type {
    width: 50%;
  }

  &--dates {
    width: 50%;
  }

  &--trailer-type {
    width: 50%;
  }

  &--plate-number {
    width: 50%;
  }

  &--driver {
    width: 50%;
  }

  &--price {
    width: 50%;
  }

  &--min-price {
    width: 25%;
    margin-right: 3*$u/2;
  }

  &--max-price {
    width: 25%;
    margin-left: 3*$u/2;
  }
}

.time-picker {
  margin-bottom: 4 * $u;
}

.selected-filters {
  padding: $u 0;
}
