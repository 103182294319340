@import "style-guide";

.field-group {
  display: flex;
  align-items: flex-start;

  & > div {
    box-sizing: border-box;
    flex-grow: 1;
  }

  &--spaces-3 {
    & > div {
      padding-left: 3*$u;
      padding-right: 3*$u;

      &:last-child {
        padding-right: 0;
      }

      &:first-child {
        padding-left: 0;
      }
    }
  }

  &--spaces-2 {
    & > div {
      padding-left: 2 * $u;
      padding-right: 2 * $u;

      &:last-child {
        padding-right: 0;
      }

      &:first-child {
        padding-left: 0;
      }
    }
  }

  &--spaces-1 {
    & > div {
      padding-left: $u;
      padding-right: $u;

      &:last-child {
        padding-right: 0;
      }

      &:first-child {
        padding-left: 0;
      }
    }
  }

  &--is-full-width {
    & > div {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__empty-item {

  }
}
