@import "style-guide";

$headerHeight: 70px;

.wrap {
  position: relative;
  background-color: $charcoal;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.inner {
  position: relative;
  height: calc(100% - #{$headerHeight});
}

.header {
  padding: 3*$u;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.icons {
  padding-bottom: 3*$u;

  // padding-left in JS

  &__inner {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  &__item {
    width: 6*$u;
    margin-left: 1.5 * $u;
    margin-right: 1.5 * $u;
    padding: $u;
    border-radius: 4px;
    box-shadow: 0 1px 10px 0 $shadow-for-dark-base;
    background-color: $charcoal;
  }
}
