@import "style-guide";

.input {
  position: relative;
}

.overlay {
  position: absolute;
  background: $white;

  @include control-shadow;

  z-index: $z-index-overlay;
  border-radius: $u;
  animation: fadeIn 0.1s ease-in;

  &--position-bottom-left {
    top: 100%;
    left: 0;
  }

  &--position-bottom-right {
    top: 100%;
    right: 0;
  }

  &--position-top-center {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  &--position-top-left {
    bottom: 100%;
    left: 0;
  }

  &--position-top-right {
    bottom: 100%;
    right: 0;
  }

  &__option {
    cursor: pointer;

    /* overflow: hidden; */
    user-select: none;

    &--isSelected,
    &:hover {
      background-color: $light-50-percent;
    }

    &:first-child {
      border-radius: $u $u 0 0;
    }

    &:last-child {
      border-radius: 0 0 $u $u;
      border-bottom: none;
    }
  }

  &__separator {
    background-color: $light-30-percent;
    height: 2 * $u;
  }
}
