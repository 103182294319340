@import "style-guide";
@import "auth";

.submit {
  @include submit-button;
}

.field {
  &--zip {
    width: 42%;
  }

  &--city {
    width: 58%;
  }
}

.error {
  @include Error-font;
}
