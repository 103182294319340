@import "style-guide";

@keyframes appearModal {
  0% {
    background-color: rgba(255, 255, 255, 0);
  }

  100% {
    background-color: rgba(255, 255, 255, 0.8);
  }
}

.modal {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  animation: appearModal $defaultAnimationDuration;
}

@keyframes appearInner {
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.inner {
  position: relative;
  border-radius: 4px;
  box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.1);
  background-color: $white;
  padding: 6*$u;
  animation: appearInner $defaultAnimationDuration;
  max-height: 95vh;
  overflow: auto;
}
