@import "style-guide";

$size: 8 * $u;

.content {
  display: inline-block;
  min-height: $size;
  min-width: $size;
  line-height: $size;

  &--withPaddings {
    min-height: $size - 2 * $defaultMapPinBorderWidth;
    min-width: $size - 2 * $defaultMapPinBorderWidth;
    line-height: $size - 2 * $defaultMapPinBorderWidth;
  }

  font-size: 28px;
  font-weight: 900;
  text-align: center;
  color: $white;
}
