@import "style-guide";

.list {
  &--hasVerticalPaddings {
    padding-top: 2*$u;
    padding-bottom: 2*$u;
  }
}

.row {
  display: flex;
  flex-wrap: nowrap;
  gap: 2*$u;

  &--isClickable {
    cursor: pointer;
  }
}

.column {
  &--content {
    flex-grow: 1;
    padding-top: $u;
    padding-bottom: $u;
  }

  &--trigger {
    width: 6 * $u;
    flex-shrink: 0;
    user-select: none;
  }
}

.content {
  position: relative;
  display: flex;
  gap: 2 * $u;
  flex-wrap: nowrap;
  justify-content: space-between;
  z-index: 0;

  &:hover {
    &::after {
      position: absolute;
      content: " ";
      background-color: $light-30-percent;
      border-radius: 4px;
      top: -$u;
      bottom: -$u;
      left: -2*$u;
      right: -2*$u;
      z-index: -1;
    }
  }
}

.content-column {
  &--icon {
    flex-shrink: 0;
    width: 4*$u;
    height: 4*$u;
    min-height: 4*$u;
  }

  &--left-node {
  }

  &--title {
    flex: 1;

    @include PriceDetails-Normal-font;
  }

  &--price {
    @include PriceDetails-Money-font;
  }

  &--placeholder {
    @include PriceDetails-Placeholder-font;
  }

  &--rightNode {

  }

  &--isLoading {
    opacity: 0.25;
  }
}

.tooltip {
  display: inline-block;
  margin-left: $u;
}

.pill {
  margin-left: 2 * $u;
}

.not-actual-price {
  margin-right: 2 * $u;
  text-decoration: line-through;
  color: $gray;
}
