@import "style-guide";

.tabs-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $white;
}

.tab {
  position: relative;
  flex-grow: 1;
}

.right-column {
  position: relative;
  width: 100%;
  height: 100%;
}

.timeline-trigger-wrap {
  position: absolute;
  right: 3 * $u;
  top: 3 * $u;
}

.timeline-trigger {
  @include control-shadow-on-map;

  background-color: $inputDefaultBackgroundColor;
}

.timeline {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}
