@import "style-guide";

.field {
  padding-bottom: $u;
}

.notes {
  &--checkbox {
    padding-left: 6 * $u;
  }
}
