@import "style-guide";

.inner {
  height: 100%;
  overflow-y: scroll;
}

.loader {
  text-align: center;
  margin-top: 10 *$u;
  margin-bottom: 10 *$u;
}

.message {
  margin-top: 6 *$u;
}

.message-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.notifications {
  padding-bottom: $u;
  padding-top: $u + $minHeaderHeight;
}
