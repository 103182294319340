@import "style-guide";

.zone-title {
  position: absolute;
  top: 100%;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.25;
  user-select: none;
  white-space: nowrap;

  &--theme-green {
    color: $brand-dark;
  }

  &--theme-orange {
    color: $squash;
  }

  &--theme-dark-green {
    color: $brand-dark;
  }

  &--position-left {
    left: 0;
  }

  &--position-center {
    width: 100%;
    text-align: center;
  }

  &--position-right {
    right: 0;
  }
}
