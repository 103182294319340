@import "style-guide";

.side-bar {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: $z-index-sidebar;

  /* transition: transform $defaultAnimationDuration; */

  &--position-left {
    left: 0;

    &.side-bar--size-regular {
      transform: translateX(-1 * $defaultSidebarWidth);
    }

    &.side-bar--size-narrow {
      transform: translateX(-1 * $narrowSidebarWidth);
    }

    &.side-bar--isShow {
      @include sidebar-shadow;

      transform: translateX(0);
    }
  }

  &--position-right {
    right: 0;

    &.side-bar--size-regular {
      transform: translateX($defaultSidebarWidth);
    }

    &.side-bar--size-narrow {
      transform: translateX($narrowSidebarWidth);
    }

    &.side-bar--isShow {
      @include sidebar-shadow;

      transform: translateX(0);
    }
  }

  &--size-regular {
    max-width: $defaultSidebarWidth;
  }

  &--size-narrow {
    max-width: $narrowSidebarWidth;
  }
}

.content {
  position: relative;
  height: 100%;
  width: 100%;
}
