@import "style-guide";

.image {
  padding: 4*$u;
  max-height: 80vh;
  max-width: 80vw;
}

.inner {
  min-width: 20*$u;
  min-height: 20*$u;
}

.action {
  position: absolute;
  top: 2 * $u;
  right: 2 * $u;
}
