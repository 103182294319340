@import "style-guide";

.inner {
  padding: 4*$u 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  margin-bottom: 2*$u;
}

.title {
  margin-bottom: 4*$u;

  @include SideBar-Message-title-font;
}

.description {
  text-align: center;
  max-width: 68*$u;

  @include SideBar-Message-description-font;

  &--has-action {
    margin-bottom: 8 * $u;
  }
}
