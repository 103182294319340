@import "style-guide";

.pill {
  display: inline-block;
  padding: 0 2*$u 0 $u;
  border-radius: 4px;

  @include TagSmallWhite-Label-font;

  &--theme-charcoal {
    background-color: $charcoal;
  }

  &--theme-grey {
    background-color: $gray;
  }

  &--theme-slate {
    background-color: $slate;
  }

  &--theme-brand-dark {
    background-color: $brand-dark;
  }

  &--theme-light {
    background-color: $light;
  }

  &--theme-brand-accent {
    background-color: $brand-accent;
  }

  &--theme-orange {
    background-color: $orange;
  }

  &--theme-azul {
    background-color: $azul;
  }

  &--theme-tomato-red {
    background-color: $tomato-red;
  }

  &--theme-blaze-orange {
    background-color: $blaze-orange;
  }

  &--is-symmetrical {
    padding: 0 $u;
  }

  &--is-rounded {
    border-radius: 8px;
  }

  &--is-no-wrap {
    white-space: nowrap;
  }
}
