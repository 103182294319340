@import "style-guide";

.inner {

}

.avatar {
  display: block;
  margin: 0 auto 4*$u;
}

.full-name {
  @include Account-Avatar-Normal-font;

  text-align: center;
}

.email {
  @include Account-Email-font;

  text-align: center;
  margin-bottom: 2*$u;
}

.time {
  @include Account-Description-font;

  text-align: center;
  margin-bottom: 4*$u;
}

.sign-out {
  width: 100%;
  margin-bottom: 6*$u;
  text-align: left;

  &__icon {
    padding-right: 2*$u;
  }
}

.link {
  &--active {
    color: $white;
    background-color: $brand-accent;
  }
}

.icon {
  margin-right: 2*$u;
}
