@import "style-guide";

.wrap {
  display: inline-block;
  padding: $u;
  border-radius: 50%;
  background-color: $white;

  @include control-shadow;
}
