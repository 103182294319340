@import "style-guide";

.content {

}

.table {
  &--id {
    margin-bottom: 4*$u;
  }

  &--po {
    margin-bottom: 4*$u;
  }

  &--point {
    margin-bottom: 4*$u;
  }

  &--shipment {
    margin-bottom: 4*$u;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}
