@import "style-guide";

.field {
  &--type,
  &--user,
  &--date,
  &--contract,
  &--plate-number,
  &--country,
  &--status {
    width: 50%;
  }
}

.button {
  width: 100%;
}

.form {
  height: 100%;
}
