@import "style-guide";

.time-picker {
  position: relative;
}

.head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.label {
  display: flex;
  flex-direction: row;
  margin-bottom: 2*$u;

  &__icon {
    margin-right: 2*$u;
  }

  &__label {
    @include Input-Label-font;

    text-transform: uppercase;
    margin-right: $u;
  }
}

.link {

}

.tooltip {
  display: inline-block;
  margin-right: 2*$u;

  &__inner {
    padding: 2*$u;
    width: 28 * $u;

    @include TooltipDark-font;

    text-align: center;
  }

  &__icon {
    vertical-align: top;
  }
}
