@import "style-guide";

.logo {
  width: 20*$u;
  height: 6*$u;
  background-image: url("images/logo.svg");
  background-position: center center;
  background-repeat: no-repeat;

  &--isSmall {
    width: 6*$u;
    height: 6*$u;
    background-image: url("images/short-logo.svg");
  }
}
