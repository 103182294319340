@import "style-guide";

.header {
  &__controls {
    margin-right: $header-controls-right-margin;
  }
}

.form {
  height: 100%;
}

.widget {
  margin-bottom: 6*$u;

  &__inner {
    padding: 6*$u 6*$u $u 6*$u;
    box-sizing: border-box;
  }
}

.control {
  &__save {
    margin-right: 10px;
  }
}

.field {
  width: 50%;

  &--language-switcher {
    padding-right: 3*$u !important;
  }
}

.account-link {
  text-transform: uppercase;

  &__wrap {
    padding: 4*$u 6*$u 6*$u 6*$u;
  }
}
