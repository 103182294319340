@import "style-guide";

.trigger {
  display: flex;
  flex-direction: row;

  &__label {
    padding: 0 2*$u;

    @include Input-Value-Normal-font;
  }
}
