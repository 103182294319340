@mixin submit-button {
  width: 100%;
  margin-bottom: 4*$u;
  font-weight: bold;
}

@mixin footer-links {
  margin-bottom: 10px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #3e3f42;
}

@mixin footer-link {
  padding-left: 8px;
}
