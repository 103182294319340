@import "style-guide";

.filters-trigger {
  margin-right: 2*$u;
}

.actions {
  margin-right: 2*$u;
}

.xls-actions {
  margin-right: 2*$u;
}

.tabs {
  margin-bottom: 2*$u;

  &--isPartner {
    margin-bottom: 0;
  }

  &__tab {
    margin-right: 2*$u;
  }
}
