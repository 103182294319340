@import "style-guide";

.loader {
  &--isAbsolute {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  &--isRelative {
    text-align: center;
  }
}

.message {
  @include SideBar-Loader-Message-font;

  color: $slate;
  margin-top: 4*$u;
}
