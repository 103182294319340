@import "style-guide";

.wrap {
  border-radius: 4px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  background-color: $white;
}

.title {
  @include secondary-font;
  @include Title-Widget-font;

  margin-right: 2*$u;
}

.header {
  min-height: 14 * $u;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid  $light;
  padding: 0 6*$u;
  box-sizing: border-box;
}

.right-node {
  display: flex;
  align-items: center;
  justify-content: right;
}

.left-node {
  display: flex;
  align-items: center;
  justify-content: left;
}

.tooltip {
  margin-right: 4*$u;
}
