@import "style-guide";

.link {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  /* start fix: overflow ellipsis */
  display: block;

  /* end fix: overflow ellipsis */
}
