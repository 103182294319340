@import "style-guide";

$scaleHeight: 4*$u;

.zone-mark {
  position: absolute;
  bottom: 0;
  z-index: $z-index-range-selector-1;
  height: $scaleHeight;
  opacity: 0.5;
  border-width: 0;
  border-style: solid;

  &--isActive {
    opacity: 1;
  }

  &--hasLeftBorder {
    border-left-width: 2px;
  }

  &--theme-green {
    border-color: $brand-accent;
  }

  &--theme-orange {
    border-color: $orange;
  }

  &--theme-dark-green {
    border-color: $brand-dark;
  }

  &--theme-light {
    border-color: $light;
  }
}
