@import "style-guide";

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.inner {
  flex: 1;
  background-color: white;
}

$tabs-breakpoint: 1200px;
$tabs-height: 10*$u;

.tab-item {
  display: flex;
  flex-basis: 50%;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  margin: 0 3*$u;
  height: $tabs-height;
  cursor: pointer;
  font-size: 14px;
  color: $slate;
  border-bottom: 3px solid transparent;

  &--isActive {
    color: $charcoal;
    border-bottom-color: $brand-accent;
  }

  &:hover {
    border-bottom-color: $brand-30-percent;
  }
}

.tabs {
  display: flex;
  flex-shrink: 0;
  border-bottom: 1px solid $light-50-percent;
  padding: 0 3*$u;

  & > * {
    flex-basis: 50%;
    flex-grow: 1;
    justify-content: center;
  }

  @media screen and (min-width: $tabs-breakpoint) {
    display: none;
  }

  &__container {
    position: relative;
    display: flex;
    flex-grow: 1;
    min-width: 1px;
    width: 100%;
    height: 100%;
    overflow: hidden;

    @media screen and (max-width: $tabs-breakpoint) {
      height: calc(100% - #{$tabs-height});
      overflow: hidden;

      & > * {
        flex-basis: 100% !important;
        flex-shrink: 0 !important;
        transition: 0.3s all;
      }
    }
  }

  &__tab {
    width: 100%;
    overflow: hidden;
    position: relative;

    &--isLeftTab {
      max-width: $defaultSidebarWidth;
      min-width: $defaultSidebarWidth;
      height: 100%;

      @media screen and (max-width: $tabs-breakpoint) {
        max-width: 100%;
      }
    }

    &--isNarrow {
      max-width: $narrowSidebarWidth;
      min-width: $narrowSidebarWidth;

      @media screen and (max-width: $tabs-breakpoint) {
        max-width: 100%;
      }
    }

    &--isRightTab {
      z-index: $z-index-form-map;
    }

    &--isActiveRightTab {
      @media screen and (max-width: $tabs-breakpoint) {
        transform: translateX(-100%);
      }
    }
  }
}

.tab {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
