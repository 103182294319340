@import "style-guide";

.route {
  margin-bottom: 5*$u;
}

.skeleton {
  &__box {
    @include base-skeletons;

    &--first {
      height: 6*$u;
      margin-bottom: 5*$u;
    }

    &--secondary {
      height: 25*$u;
      margin-bottom: 4*$u;
    }
  }
}
