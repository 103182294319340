@import "style-guide";

.zone-title {
  position: absolute;
  top: 100%;
  left: 0;
  user-select: none;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.25;
  color: $gray;
  white-space: nowrap;
}
