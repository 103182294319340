@import "style-guide";

.content {
  display: flex;
  align-items: center;
  padding: 2*$u;
}

.last-track-point-updated {
  padding: 0 2*$u;
}
