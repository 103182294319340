@import "style-guide";

.tabs {
  position: relative;
  display: flex;
  padding: 0 6*$u;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(107, 108, 111, 0.4);
  }
}
