@import "style-guide";

.title {
  @include secondary-font;

  font-size: 18px;
  line-height: 1.11;
  color: $charcoal;
  flex-grow: 1;
}

.download {
  margin-right: 4*$u;
}

.info {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  min-height: 8*$u;
}
