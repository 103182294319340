@import "style-guide";

.title {
  @include TextNotification-Text-font;

  margin-bottom: 2*$u;
  color: $white;
}

.inner {
  background-image: url("images/warning.svg");
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center left;
}

.message {
  color: $white;
}

.notification {
  width: 70*$u;
  background-color: #5c7e1d;
  border-radius: 4px;
}

.content {
  display: flex;
  padding: 4*$u 6*$u;

  &__left {
    flex: 1;
  }

  &__right {
    padding-left: $u;
  }
}

.close {
  cursor: pointer;
  outline: none;
  box-shadow: none;
  background-color: transparent;
  border: none;
}
