@import "style-guide";

.pill {
  &--is-spot {
    margin-right: $u;
  }

  &--is-archive {
    margin-left: $u;
  }
}
