@import "style-guide";

.address {
  @include Table-Row-font;
}

.time {
  @include Table-Row-Secondary-font;

  &--isSmallFont {
    @include Table-Row-Secondary-small-font;
  }
}
