@import "style-guide";

.filters-trigger {
  margin-right: 2*$u;
}

.sort-trigger {
  margin-right: 2*$u;
}

.actions {
  margin-right: 2*$u;
}

.actions-overlay {
  width: 36*$u;
}

.tabs {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-bottom: 2*$u;

  &__tab {
    margin-right: 4*$u;
  }
}
