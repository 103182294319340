@import "style-guide";

@mixin cleanButtonStyles {
  box-shadow: none;
  border: none;
}

.icon-trigger {
  border-radius: $controlDefaultBorderRadius;
  outline: none;
  border: none;

  @include Input-Value-Normal-font;
  @include base-font;

  &--theme-primary {
    color: $white;
    background-color: #7ca829;

    &.icon-trigger--isFocused,
    &:hover {
      background-color: #759e27;
    }

    &.icon-trigger--isPressed,
    &:active {
      background-color: #678c22;
    }
  }

  &--theme-secondary {
    color: $charcoal;
    background-color: $light-30-percent-non-alpha;

    &.icon-trigger--isFocused,
    &:hover {
      background-color: $light-50-percent;
    }

    &.icon-trigger--isPressed {
      background-color: $inputPressedBackgroundColor;
    }

    &:active {
      background-color: $light;
    }
  }

  &--theme-danger {
    color: #fff;
    background-color: #d32406;

    &.icon-trigger--isFocused,
    &:hover {
      background-color: #c92205;
    }

    &.icon-trigger--isPressed,
    &:active {
      background-color: #be2005;
    }
  }

  &--theme-transparent {
    color: $charcoal;
    background-color: transparent;

    &.icon-trigger--isFocused,
    &:hover {
      background-color: $light-50-percent;
    }

    &.icon-trigger--isPressed {
      background-color: $inputPressedBackgroundColor;
    }

    &:active {
      background-color: $light;
    }
  }

  &--isDisabled {
    background-color: $slate !important;
    color: $white;

    @include cleanButtonStyles;

    &.icon-trigger--isFocused,
    &:hover {
      @include cleanButtonStyles;
    }

    &.icon-trigger--isPressed,
    &:active {
      @include cleanButtonStyles;
    }
  }

  &--isToggled {
    background-color: $inputFocusBackgroundColor !important;

    @include cleanButtonStyles;

    &:hover {
      @include cleanButtonStyles;
    }

    &.icon-trigger--isPressed,
    &:active {
      @include cleanButtonStyles;
    }
  }

  &--isClickable {
    cursor: pointer;
  }
}
