@import "style-guide";

.form {
  height: 100%;
}

.button {
  width: 100%;
}

.field {
  &--trailer-type {
    margin-bottom: 4*$u;
  }
}

.alert {
  margin-bottom: 4*$u;
}
