@import "style-guide";

.route-point-pin {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8*$u;
  user-select: none;
  transform: translate(-50%, -100%);
}
