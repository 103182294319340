@import "style-guide";

.wrap {
  @include Notification-Item-font;
}

.message {
  margin-bottom: 2*$u;
}

.description {
  @include Notification-Status-font;

  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.status {
  padding-left: 2*$u;
}

.action {
  padding-right: $u;
}
