@import "style-guide";

$triggerSize: 4*$u;
$triggerOffset: 1px;

.trigger {
  cursor: grab;
  position: absolute;
  width: $triggerSize;
  height: 100%;
  z-index: $z-index-range-selector-2;
  background-repeat: no-repeat;

  &--isLeft {
    left: -1 * ($triggerSize - $triggerOffset) / 2;
  }

  &--isRight {
    right: -1 * ($triggerSize - $triggerOffset) / 2;
  }

  &--isDisabled {
    cursor: not-allowed;
  }
}

.line {
  position: relative;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  background-color: lightblue;
  width: 2px;
  height: 100%;
}
