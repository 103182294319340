@import "style-guide";

.inner {
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: $controlDefaultBorderRadius;
}

.head {
  cursor: pointer;
  padding: 5*$u 6*$u;

  &--isNarrow {
    padding: 0 2*$u;
  }

  &--isOpened {
    border-bottom: 1px solid $light;
  }
}

.body {
  padding: 4*$u 5*$u;
}

.main-details {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  flex-grow: 1;
  padding: $u 0;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
}

.price {
  @include TextBig-price-font;

  color: $charcoal;
  margin-right: 2*$u;

  &--unactive {
    font-size: 18px;
    color: $slate;
    margin-right: 3*$u;
  }
}

.status {
  cursor: pointer;
  margin-top: $u;
  margin-bottom: $u;
  margin-right: 2*$u;
}

.reject {
  margin-left: 2*$u;
}
