@import "style-guide";

.table {
  &__header {
    @include Table-Header-font;

    padding: 0 5*$u 2*$u 5*$u;
    text-align: left;
    white-space: nowrap;

    &--checkbox {
      width: 5*$u;
      padding: 0 0 2*$u 5*$u;
    }

    &--emission-class {
      text-align: center;
      white-space: nowrap;
    }

    &--linked,
    &--dedicated {
      text-align: center;
    }
  }

  &__column {
    &--checkbox {
      width: 5*$u;
      padding: 5*$u 0 5*$u 5*$u;
    }

    &--any {
      padding: 0 5*$u;
      flex-grow: 1;
    }

    &--created {
      padding: 0 5*$u;
      flex-grow: 1;
      max-width: 50 * $u;
    }

    &--plate-number {
      padding: 5*$u;
      flex-grow: 1;
    }

    &--status {
      width: 8*$u;
      padding: 5*$u;
    }
  }
}
