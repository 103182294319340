@import "style-guide";

.option {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__icon {
    margin-right: 2*$u;
  }
}
