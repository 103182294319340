@import "style-guide";

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 3*$u 6*$u;
  background-color: #fff;
  min-height: $minHeaderHeight;
}

.title {
  @include secondary-font;
  @include Title-Widget-font;
}

.spacer {
  flex-grow: 1;
}

.after-title-node {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 2*$u;
}

.right-node {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 2*$u;
}

.control {
  &--has-right-padding {
    margin-right: 2 * $u;
  }

  &--back-button {
    margin-right: 2*$u;
  }

  &--close-button {

  }
}

.after-dropdown-node {
  margin-right: 2 * $u;
}

.skeleton {
  @include base-skeletons;

  height: 4*$u;
  width: 30%;
}
