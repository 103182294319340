@import "style-guide";

.trigger {
  width: 100%;
  justify-content: space-between;
}

.overlay {
  width: 100%;
}
