@import "style-guide";

.pin {
  display: inline-block;
  position: absolute;
}

.wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  user-select: none;
  transform: translate(-50%, -100%);
}
