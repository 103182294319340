@import "style-guide";

.checkbox {
  display: flex;
  align-items: center;

  &--isFullWidth {
    width: 100%;
  }
}

.box {
  $class: &;

  position: relative;
  margin: 2px;
  height: 16px;
  width: 16px;
  min-width: 16px;
  border-radius: 4px;
  border: solid 2px $light;
  background-color: $inputHoverBackgroundColor;

  &--isClickable {
    cursor: pointer;
    user-select: none;
  }

  &--isHover {
    border: solid 2px $light;
    background-color: #f4f5f8;
  }

  &--isChecked {
    border-color: #7ca829;
    background-color: #7ca829;

    &#{$class}--theme-orange {
      border-color: $orange;
      background-color: $orange;
    }

    &::after {
      content: " ";
      position: absolute;
      width: 2*$u;
      height: 2*$u;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-image: url("../../../common/images/active-check.svg");
    }
  }

  &--isIndeterminated {
    border-color: #7ca829;
    background-color: #7ca829;

    &#{$class}--theme-orange {
      border-color: $orange;
      background-color: $orange;
    }

    &::after {
      content: " ";
      position: absolute;
      width: 2*$u;
      height: 2*$u;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-image: url("../../../common/images/indeterminated-check.svg");
    }
  }

  &--isChecked.box--isHover {
    border-color: #759e27;
    background-color: #759e27;

    &#{$class}--theme-orange {
      border-color: $squash;
      background-color: $squash;
    }
  }

  &--isDisabled {
    border-color: $light;
    background-color: $light-30-percent;
    cursor: not-allowed;
  }

  &--isChecked.box--isDisabled {
    border-color: $light;
    background-color: $light-30-percent;

    &::after {
      background-image: url("../../../common/images/active-disabled-check.svg");
    }
  }

  &--isDisabled.box--isIndeterminated {
    &::after {
      background-image: url("../../../common/images/indeterminated-disabled-check.svg");
    }
  }
}

.label {
  margin-left: 2 * $u;

  @include Input-Value-Normal-font;

  &--isFullWidth {
    flex-grow: 1;
  }

  &--isClickable {
    cursor: pointer;
    user-select: none;
  }

  &--isDisabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &--hasRightIcon {
    margin-right: 2 * $u;
  }
}
