@import "style-guide";

.line-loader {
  height: 2px;
  border-radius: 2.5px;

  /* transform: translateY(2px); */
  opacity: 0;
  transition: opacity 300ms ease-in-out;

  // hot fix chrome bug: visible with opacity: 0
  visibility: hidden;

  &--is-show {
    background: linear-gradient(to right, $gray, $gray);
    background-color: $light;
    background-size: 20%;
    background-repeat: repeat-y;
    background-position: -25% 0;
    animation: line-loader-scroll 2s ease-in-out infinite;
    opacity: 1;

    // hot fix chrome bug: visible with opacity: 0
    visibility: visible;
  }
}

@keyframes line-loader-scroll {
  50% {
    background-size: 80%;
  }

  100% {
    background-position: 125% 0;
  }
}
