@import "style-guide";

$optionVerticalPadding: 2*$u;
$optionHorizontalPadding: 2*$u;

.option {
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  padding-right: $optionHorizontalPadding;

  @include Text-overflow-ellipsis;
  @include Dropdown-ListBold-font;

  &:hover {
    background-color: $optionHoverBackgroundColor;
  }

  &__checkbox {
    padding-left: $optionHorizontalPadding;
  }

  &__label {
    padding: $optionVerticalPadding 0;
  }
}

.overlay {
  min-width: 100%;
}

.separator {
  background-color: $light-30-percent;
  height: 2 * $u;
}
