@import "style-guide";
@import "z-index";

$defaultHorizontalPadding: 6*$u;

.navigation {
  background-color: $white;
  min-width: 16*$u;
  max-width: 16*$u;
  padding-bottom: 10*$u;

  &--isExpanded {
    min-width: 54*$u;
    max-width: 54*$u;
  }

  @media screen and (max-width: 768px) {
    z-index: $z-index-navigation;
    position: fixed;
    right: 100%;
  }
}

.inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  min-height: 1px;

  @media screen and (max-width: 768px) {
    height: 100vh;
  }
}

.menu {
  flex-grow: 1;
}

.logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 14*$u;
  margin: 0 auto;

  &__link {
    margin: 0 auto;
    display: inline-block;
  }
}

.avatar {
  display: flex;
  margin-top: 4*$u;
  margin-bottom: 4*$u;
  padding: 4*$u;

  &--isExpanded {
    padding: 4*$u $defaultHorizontalPadding;
  }

  &__icon {
    min-width: 8*$u;
    position: relative;

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      border: 0 solid $brand-accent;
    }

    &--isHovered {
      &::after {
        border-width: 1px;
        width: calc(100% + 3px);
        height: calc(100% + 3px);
      }
    }

    &--hasLinkMatch {
      &::after {
        border-width: 2px;
        width: calc(100% + 3px);
        height: calc(100% + 3px);
      }
    }
  }

  &__inner {
    padding-left: 2*$u;
    padding-right: 2*$u;
  }

  &__user {
    @include Avatar-User-font;

    &--isInTooltip {
      color: $light;
    }
  }

  &__company {
    @include Avatar-Company-font;
  }
}

.about {
  padding-top: 5*$u;
  padding-bottom: 3*$u;

  &__inner {
    padding: 0 $defaultHorizontalPadding;
  }

  &__copyright {
    @include Copyright-font;

    margin-bottom: 2*$u;
  }

  &__disclaimer {
    @include Disclaimer-font;
  }
}

.light-menu-item {
  display: block;
  height: 8*$u;
  padding: 2*$u;
  border-radius: $controlDefaultBorderRadius;

  &:hover {
    background-color: $light-50-percent;
  }

  &__wrap {
    position: relative;
    margin-left: 4*$u;
    margin-right: 4*$u;
  }

  &--isActive {
    background-color: $brand-5-percent;

    &:hover {
      background-color: $brand-5-percent;
    }
  }

  &--isClickable {
    cursor: pointer;
  }
}

.menu-item {
  position: relative;
  display: flex;
  padding: 2*$u $defaultHorizontalPadding;

  &__icon {
    height: 4*$u;
    width: 4*$u;
  }

  &__text {
    padding-left: 2*$u;
    font-weight: bold;

    @include Menu-Item-font;

    &--isActive {
      color: $brand-dark;
    }
  }

  &:hover {
    background-color: $light-50-percent;
  }

  &--isActive {
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      content: " ";
      width: 3px;
      height: 100%;
      background-color: $brand-accent;
    }
  }

  &--isClickable {
    cursor: pointer;
  }
}

.count {
  @include Menu-Item-font;
}

.tooltip {
  padding: 2*$u;

  @include TooltipDark-font;

  &__text {
    max-width: 60*$u;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--isAvatar {

  }

  &--isMenuItem {
    white-space: nowrap;
  }
}
