@import "style-guide";

.wrap {
  padding-top: 2*$u;

  &--hasTopPadding {
    padding-top: 8*$u;
  }
}

.content {
  padding-bottom: 6*$u;
}

.icon {
  height: 26*$u;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 4*$u;
  text-align: center;
}

.title {
  @include Modal-Title-font;

  text-align: center;
  margin-bottom: 6*$u;
}

.message {
  @include Modal-Text-font;

  text-align: center;
  margin-bottom: 3*$u;
}

.actions {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;

  &__action {
    width: 50%;
    max-width: 50%;
    margin: 0 $u;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
