@import "style-guide";

.version {
  border-radius: 4px;
  box-shadow: 0 1px 5px 0 $black-10-percent;
  background-color: #fff;
  padding: 3*$u 4*$u;
}

.status {
  margin-right: 2*$u;
}

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 2*$u;

  &__title {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: $charcoal;
    flex-grow: 1;
  }
}
