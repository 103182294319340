@import "style-guide";

.wrap {
  @include Table-Row-font;

  &--isSmallFont {
    @include Table-Row-small-font;
  }
}

.link {
  white-space: nowrap;
}
