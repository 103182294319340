@import "style-guide";

.margin {
  &--positive {
    color: $brand-dark;
  }

  &--negative {
    color: $tomato-red;
  }

  &--not-actual {
    color: $light;
  }
}

.not-number {
  color: $light;
}
