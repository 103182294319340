@import "style-guide";

$scaleHeight: 4*$u;

.zone {
  position: absolute;
  bottom: 0;
  z-index: $z-index-range-selector-1;
  width: 100%;
  height: $scaleHeight;
  background-size: 5px 5px;

  &--isSelected.zone--theme-green {
    background-image: linear-gradient(45deg, #9cc257 5%, #a6cb62 5%, #a6cb62 50%, #9cc257 50%, #9cc257 55%, #a6cb62 55%, #a6cb62 100%);
  }

  &--isSelected.zone--theme-green.zone--isHovered {
    background-image: linear-gradient(45deg, #8cc02b 5%, #9ac356 5%, #9ac356 50%, #8cc02b 50%, #8cc02b 55%, #9ac356 55%, #9ac356 100%);
  }

  &--isSelected.zone--theme-orange {
    background-image: linear-gradient(45deg, #d5a800 5%, #f7b039 5%, #f7b039 50%, #d5a800 50%, #d5a800 55%, #f7b039 55%, #f7b039 100%);
  }

  &--isSelected.zone--theme-orange.zone--isHovered {
    background-image: linear-gradient(45deg, #d5a800 5%, #ea9200 5%, #ea9200 50%, #d5a800 50%, #d5a800 55%, #ea9200 55%, #ea9200 100%);
  }

  &--isSelected.zone--theme-dark-green {
    background-image: linear-gradient(45deg, #607d30 5%, #597627 5%, #597627 50%, #607d30 50%, #607d30 55%, #597627 55%, #597627 100%);
  }

  &--isSelected.zone--theme-dark-green.zone--isHovered {
    background-image: linear-gradient(45deg, #607d30 5%, #597627 5%, #597627 50%, #607d30 50%, #607d30 55%, #597627 55%, #597627 100%);
  }

  &--theme-green {
    background-image: linear-gradient(45deg, #e1eccc 5%, #e5f0d0 5%, #e5f0d0 50%, #e1eccc 50%, #e1eccc 55%, #e5f0d0 55%, #e5f0d0 100%);
  }

  &--theme-orange {
    background-image: linear-gradient(45deg, #f4e5bd 5%, #fce5bd 5%, #fce5bd 50%, #f4e5bd 50%, #f4e5bd 55%, #fce5bd 55%, #fce5bd 100%);
  }

  &--theme-dark-green {
    background-image: linear-gradient(45deg, #607d30 5%, #597627 5%, #597627 50%, #607d30 50%, #607d30 55%, #597627 55%, #597627 100%);
  }

  &--theme-light {
    background-color: $light-30-percent;
  }

  &--theme-charcoal {
    background-color: $charcoal;
  }

  &--isDisabled {
    background-image: linear-gradient(45deg, #8c8d8e 5%, #9ea0a4 5%, #9ea0a4 50%, #8c8d8e 50%, #8c8d8e 55%, #9ea0a4 55%, #9ea0a4 100%);
  }

  &--hasLeftBorder {
    border-top-left-radius: $controlDefaultBorderRadius;
    border-bottom-left-radius: $controlDefaultBorderRadius;
  }

  &--hasRightBorder {
    border-top-right-radius: $controlDefaultBorderRadius;
    border-bottom-right-radius: $controlDefaultBorderRadius;
  }
}
