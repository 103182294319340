@import "style-guide";

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 2 * $u;
}

.action {
  &--assign {
    flex-grow: 1;
  }
}

.time-left {
  margin-right: 4 * $u;
}

.spacer {
  flex-grow: 1;
}

.button {
  width: 100%;
}
