@import "style-guide";

.tabs {
  display: flex;
  flex-direction: row;
  justify-content: left;

  &__tab {
    margin-right: 4*$u;
  }
}

.alert {
  margin-top: 8*$u;
  margin-bottom: 4*$u;
}
