@import "style-guide";

.content {
  &--with-top-padding {
    padding-top: 2*$u;
  }
}

.tools {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2*$u;
}

.left-tools {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.right-tools {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.filter-tags {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  min-height: 6*$u;
}
