@import "style-guide";

.option {
  display: flex;
}

.avatar {
  margin-right: 2*$u;
  padding-bottom: 1px;
  vertical-align: middle;
}

.label {
  max-width: 50*$u;
  padding-right: 2*$u;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
