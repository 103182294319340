@import "style-guide";

.text {
  &--is-capitalized {
    text-transform: capitalize;
  }

  &--is-uppercase {
    text-transform: uppercase;
  }
}
