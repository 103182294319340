@import "style-guide";

.overlay {
  position: absolute;
  padding: 4*$u + 1px;
  border-radius: $controlDefaultBorderRadius;
  background: white;

  @include control-shadow;

  z-index: $z-index-date-picker;
  animation: fadeIn 0.1s ease-in;

  &--position-bottom-left {
    top: 100%;
    left: 0;
  }

  &--position-bottom-right {
    top: 100%;
    right: 0;
  }

  &--position-top-left {
    bottom: 100%;
    left: 0;
  }

  &--position-top-right {
    bottom: 100%;
    right: 0;
  }
}
