@import "style-guide";

.content {
  padding: 4*$u 6*$u;
}

.action {
  &--reject {
    flex-grow: 1;
  }

  &--approve {
    flex-grow: 2;
  }
}

.actions {
  flex-grow: 1;
  display: flex;
  gap: 2 * $u;
}
