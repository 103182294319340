@import "style-guide";

.dropdown {

}

.trigger-wrap {
  position: relative;
}

$borderWidth: 2px;

.trigger {
  padding: ($u - $borderWidth);
  border: solid $borderWidth transparent;
  background-color: $labelDefaultBackgroundColor;
  border-radius: $controlDefaultBorderRadius;

  &--isHovered {
    background-color: $inputHoverBackgroundColor;
  }

  &--isFocus {
    background-color: $inputFocusBackgroundColor;
    border-color: transparent;
  }

  &--isDisabled {
    background-color: $inputDisabledBackgroundColor;
    border-color: $inputDisabledBackgroundColor;
    cursor: not-allowed;
  }

  &--hasWarning {
    border-color: $inputWarningBorderColor;
  }

  &--hasChanges {
    border-color: $inputWarningBorderColor;
  }

  &--hasError {
    border-color: $inputErrorBorderColor;
  }
}

.overlay {
  position: absolute;
  width: 100%;
  background-color: $white;

  @include control-shadow;

  border-radius: $controlDefaultBorderRadius;
  z-index: $z-index-overlay;
  animation: fadeIn 0.1s ease-in;
  max-height: 60*$u;
  overflow-y: auto;

  &--top {
    bottom: 100%;
  }

  &--bottom {
    top: 100%;
  }
}
