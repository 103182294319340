@import "style-guide";

.text {
  @include TextNotification-Base-font;

  padding: $u 0;
  color: $white;
}

.content {
  display: flex;
  padding: 3*$u 3*$u 3*$u 5*$u;
  background-color: $charcoal;

  &--has-icon {
    padding-left: 4*$u;
  }

  &__left-icon {
    padding-top: $u;
    padding-right: 2*$u;
  }

  &__content {
    flex: 1;
  }

  &__link-icon {
    margin-right: 2 * $u;
  }

  &__close-icon {

  }
}
