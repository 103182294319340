@import "style-guide";

.date {
  font-size: 12px;
  line-height: 1.43;
  color: $charcoal;
  white-space: nowrap;

  &--isBold {
    font-weight: bold;
  }

  &--isBigFont {
    font-size: 14px;
  }
}
