@import "style-guide";

$borderWidth: $u;
$verticalNotficiationPadding: 2*$u;
$horizontalNotficiationPadding: 3*$u;

.wrap {
  margin: 2*$u 4*$u;
  padding: $verticalNotficiationPadding $horizontalNotficiationPadding $verticalNotficiationPadding ($horizontalNotficiationPadding - $borderWidth);
  border-left: $borderWidth solid transparent;

  &:last-child {
    margin-bottom: $u;
  }

  &:first-child {
    margin-top: $u;
  }

  &--has-emotion {
    border-radius: 4px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  }

  &--emotion-neutral {
    border-left-color: $slate;
  }

  &--emotion-positive {
    border-left-color: $brand-accent;
  }

  &--emotion-negative {
    border-left-color: $tomato-red;
  }

  &--emotion-attention {
    border-left-color: $orange;
  }

  &--emotion-null {
    background-color: #fefeff;

    &:hover {
      background-color: #fbfcfd;
    }
  }

  &--is-clickable {
    cursor: pointer;
  }
}

.time {
  @include Notification-time-font;
}
