@import "style-guide";

.wrap {
  border-radius: 4px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
}

.title {
  @include secondary-font;
  @include Title-Card-font;
}

.header {
  min-height: 14 * $u;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3*$u 0 6*$u;
  box-sizing: border-box;
  cursor: pointer;
}

.right-node {
  display: flex;
  align-items: center;
  justify-content: right;
  flex-wrap: nowrap;
  gap: 2 * $u;
}

.left-node {
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: nowrap;
  gap: 2 * $u;
}

.content {
  padding: 4*$u 6*$u 3*$u 6*$u;

  &--has-top-border {
    border-top: 1px solid  $light;
  }
}

.tooltip {
  margin-right: 4*$u;
}
