@import "style-guide";

.button {
  width: 100%;
}

.form {
  height: 100%;
}

.field {
  &--plate-number,
  &--emission-class,
  &--status,
  &--country,
  &--contract,
  &--user,
  &--date {
    width: 50%;
  }

  &--link {
    width: 30%;
    margin-top: 3*$u;
    margin-bottom: 8*$u;
  }
}
