@import "style-guide";

.menu {
  transition: $defaultAnimationDuration transform;

  &--isOpen {
    @media screen and (max-width: 768px) {
      transform: translateX(54*$u);
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  min-height: 1px;
}

.wrap {
  display: flex;
  flex-grow: 1;
  height: 100%;
  min-width: 1px;
  width: 100%;
}

.inner {
  position: relative;
  height: 100%;
  overflow: hidden;
}
