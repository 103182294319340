@import "style-guide";
/* stylelint-disable no-duplicate-selectors */

$borderRadius: 4px;
$badgeWidth: $u;

.table {
  position: relative;
  background: transparent;

  table {
    width: 100%;
    border-spacing: 0 2*$u;
  }

  thead {
    tr {
      position: relative;
    }

    th {
      @include Input-Label-font;
    }

    .header {
      text-align: left;

      &--mod-center {
        text-align: center;
      }
    }
  }

  tbody {
    tr {
      padding: 4*$u;

      td {
        position: relative;

        &:first-child {
          border-top-left-radius: $borderRadius;
          border-bottom-left-radius: $borderRadius;
        }

        &:last-child {
          border-top-right-radius: $borderRadius;
          border-bottom-right-radius: $borderRadius;
        }

        /* сноска слева */
        &:first-child {
          &::after {
            content: " ";
            position: absolute;
            top: 0;
            left: 0;
            width: $badgeWidth;
            height: 100%;
            background-color: transparent;
            border-bottom-left-radius: $badgeWidth;
            border-top-left-radius: $badgeWidth;
          }
        }

        /* сноска справа */
        &:last-child {
          &::after {
            content: " ";
            position: absolute;
            top: 0;
            right: 0;
            width: $badgeWidth;
            height: 100%;
            background-color: transparent;
            border-bottom-right-radius: $badgeWidth;
            border-top-right-radius: $badgeWidth;
          }
        }
      }
    }
  }

  .row {
    border-radius: $borderRadius;

    &--theme-empty {
      background-color: $light-10-percent;

      &:hover {
        background-color: $light-30-percent;
      }
    }

    &--theme-normal {
      cursor: pointer;
      box-shadow: 0 1px 5px 0 $black-10-percent;
      background-color: $white;

      &:hover {
        background-color: $light-5-percent;
      }
    }

    &--hasLeftGreenBorder {
      td {
        /* сноска слева */
        &:first-child {
          &::after {
            background-color: $brand-accent;
          }
        }
      }
    }

    &--hasLeftRedBorder {
      td {
        /* сноска слева */
        &:first-child {
          &::after {
            background-color: $tomato-red;
          }
        }
      }
    }

    &--hasLeftGreyBorder {
      td {
        /* сноска слева */
        &:first-child {
          &::after {
            background-color: $light;
          }
        }
      }
    }

    &--hasLeftOrangeBorder {
      td {
        /* сноска слева */
        &:first-child {
          &::after {
            background-color: $orange;
          }
        }
      }
    }
  }
}

.line-loader-container {
  position: absolute;
  left: 0;
  bottom: -1*$u;
  width: 100%;
}
/* stylelint-enable no-duplicate-selectors */
