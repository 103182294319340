@import "style-guide";

.count {
  margin-right: $u;
}

.inner {
  display: flex;
}

.overlay {
  white-space: nowrap;
}

.header {
  display: flex;
}

.header-count {
  margin-left: 2*$u;
}
