@import "style-guide";

.option {
  position: relative;
  padding: 2*$u;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: $optionPhoneNumber;

  &--isSelectable {
    cursor: pointer;

    &:hover {
      background-color: $optionHoverPhoneNumber;
    }
  }

  &--isSelected {
    background-color: $optionSelectedPhoneNumber;

    &:hover {
      background-color: $optionSelectedPhoneNumber;
    }
  }

  &__flag {
    margin-right: 2 * $u;
  }

  &__label {
    flex-grow: 1;

    @include Dropdown-ListNormal-font;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__icon {
    padding-left: 2 * $u;
  }
}
