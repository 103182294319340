@import "style-guide";

.left-card-layout {
  padding-top: 6*$u;
  display: flex;
  flex-direction: row;
  flex-grow: 1;

  $break-point: 1100px;

  @media screen and (max-width: $break-point) {
    flex-direction: column;
    align-items: center;
  }

  &__left {
    width: 100%;
    max-width: 66*$u;
    padding-right: 6*$u;

    @media screen and (max-width: $break-point) {
      max-width: 600px;
      padding: 6*$u;
    }
  }

  &__right {
    max-width: 124*$u;
    width: 100%;
    flex: 1;
  }
}
