@import "style-guide";

.wrap {
  margin-top: 20*$u;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  max-width: 40*$u;
}

.icon {
  margin-bottom: 2*$u;
  text-align: center;
}

.title {
  @include Modal-Title-font;

  text-align: center;
  margin-bottom: 4*$u;
}

.description {
  @include Modal-Text-font;

  text-align: center;
  margin-bottom: 8*$u;
}

.action {
  text-align: center;
}

.button {
  min-width: 32 * $u;
}
