@import "style-guide";

.map-tooltip-wrap {
  display: inline-block;
  transform: translate(-4*$u, -100%);
  cursor: pointer;
  position: relative;
}

.footer-wrap {
  position: absolute;
  top: 100%;
  left: 0;
}
