@import "style-guide";

.table {
  &__header {
    @include Table-Header-font;

    padding: 0 3*$u 2*$u 3*$u;
    text-align: left;

    &--id {
      padding: 0 3*$u 2*$u 6*$u;
    }

    &--type {
      text-align: center;
    }
  }

  &__column {
    padding: 3*$u;

    &--id {
      padding: 3*$u 3*$u 3*$u 6*$u;
    }

    &--partners {
      padding: 3*$u;
      width: 20%;
    }

    &--create {
      min-width: 24*$u;
    }

    &--locations {
      padding: 3*$u;
      width: 20%;
    }

    &--type {
      text-align: center;
    }

    &--has-warnings {
      min-width: 7*$u;
      padding-right: 0;
    }

    &--status {
      padding: 3*$u 6*$u 3*$u 2*$u;
    }
  }
}
