@import "style-guide";

.overlay {
  width: 100%;
}

.options {
  max-height: 40*$u;
  overflow-y: auto;
}

.option {
  padding: 2*$u 3*$u;
  cursor: pointer;
  white-space: nowrap;

  @include Text-overflow-ellipsis;
  @include Dropdown-ListBold-font;

  &:hover {
    background-color: $optionHoverBackgroundColor;
  }

  &--isSelected {
    background-color: $inputHoverBackgroundColor;

    &:hover {
      background-color: $inputHoverBackgroundColor;
    }
  }
}

$iconSize: 4*$u;
$iconPadding: 2*$u;

.search-input {
  width: 100%;
  border: solid $inputBorderWidth transparent;

  @include base-font;
  @include Input-Value-Bold-font;

  padding: ((2*$u) - $inputBorderWidth) ((3*$u) - $inputBorderWidth);
  padding-right: 2*$iconPadding + $iconSize - $inputBorderWidth;
  border-radius: $controlDefaultBorderRadius;
  background-color: $inputDefaultBackgroundColor;
  outline: none;

  &:hover {
    background-color: $inputHoverBackgroundColor;
  }

  &:focus {
    background-color: $inputFocusBackgroundColor;
  }

  &:placeholder {
    @include Input-Value-Placeholder-font;
  }

  &--isFocus {
    background-color: $inputFocusBackgroundColor;
    border-color: transparent;
  }

  &--isEmpty {
    @include Input-Value-Placeholder-font;
  }

  &__wrap {
    position: relative;
  }

  $iconSize: 4*$u;
  $iconHorizontalPadding: 2*$u;
  $iconVerticalPadding: 2*$u;

  &__icon {
    position: absolute;
    width: $iconSize;
    height: $iconSize;

    &--search {
      right: $iconHorizontalPadding;
      top: $iconVerticalPadding;
    }

    &--right-icon {
      right: $iconSize + 2 * $iconHorizontalPadding;
      top: $iconVerticalPadding;
    }
  }
}

.separator {
  background-color: $light-30-percent;
  height: 2 * $u;
}
