@import "style-guide";

.pagination {
  display: inline-flex;
  overflow: hidden;
  background-color: $white;
  border-radius: 4px 4px 0 0;

  @include control-shadow;
}

.input-wrap {
  position: relative;
}

.width-machine {
  opacity: 0;
  padding: 0 3*$u + 1px; // 1px fix for disable scroll

  @include Input-Value-Bold-font;
}

.input {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
