@import "style-guide";

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.calendar {
  margin-bottom: 3*$u;
}

.input {

}
