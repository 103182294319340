@import "style-guide";

.text {
  padding-right: $u;

  @include field-label;
}

.tooltip {
  display: inline-block;

  &__icon {
    vertical-align: top;
  }
}

.label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 4*$u;
  margin-bottom: $u;
}

.left-node {
  flex-grow: 1;
}

.right-node {

}
