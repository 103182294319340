@import "style-guide";

.inner {
  padding-top: 11*$u;
  padding-bottom: 18*$u;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  margin-bottom: 2*$u;
}

.title {
  margin-bottom: 4*$u;

  @include SideBar-Message-title-font;
}

.description {
  @include SideBar-Message-description-font;
}
