@import "style-guide";
@import "auth";

.submit {
  @include submit-button;
}

.footer-links {
  @include footer-links;

  &__link {
    @include footer-link;
  }
}

.result {
  width: 20*$u;
  height: 20*$u;
  border-radius: 50%;
  background-color: #8aba2e;
  margin: 9*$u auto;
  display: flex;
  justify-content: center;
  align-items: center;

  &__done {
    color: #fff;
    padding: $u;
    font-size: 8px;
  }
}

.input {
  padding-bottom: 2*$u;
}
