@import "style-guide";
@import "auth";

.checkbox {
  margin-bottom: 2*$u;

  &__error {
    @include Error-font;

    margin-top: 0 !important;
  }
}

.terms {
  color: $charcoal;
}

.submit {
  @include submit-button;
}

.footer-links {
  @include footer-links;

  &__link {
    @include footer-link;
  }
}
