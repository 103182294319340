@import "style-guide";

.form {
  padding-bottom: 2*$u;
}

.field {

}

.overlay {
  width: 100%;
}
