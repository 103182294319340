@import "style-guide";

.loader {
  display: inline-block;
  padding: $u 2*$u $u $u;
  border-radius: 3*$u;

  @include control-shadow;

  background-color: $white;

  &--is-invert {
    background-color: $charcoal;
  }

  &__text {
    font-size: 14px;
    font-weight: bold;
    padding-left: $u;
    color: $slate;

    &--is-invert {
      color: $light;
    }
  }
}
