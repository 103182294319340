@import "style-guide";

$leftIconWidth: 4*$u;
$leftIconPadding: 2*$u;
$leftIconSpace: $leftIconWidth + $leftIconPadding;
$nameWidth: 28*$u;
$defaultOpenedMarginSize: 2 * $u;

.grid {
  &--has-symmetrical-opened-margins {
    margin-bottom: $defaultOpenedMarginSize;
  }

  &__row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    &--has-icons {
      padding-left: $leftIconSpace;
    }

    &--is-opened-first-row {
      margin-bottom: $defaultOpenedMarginSize;
    }
  }

  &__cell {
    padding: $u 0;

    &--hasBottomBorder {
      border-bottom: 1px solid $light;
    }

    &--isIcon {
      width: $leftIconWidth;
      flex-shrink: 0;
      margin-right: $leftIconPadding;
    }

    &--isName {
      @include Info-table-name-normal-font;

      flex-grow: 1;
      padding-right: 2*$u;
    }

    &--isFixedName {
      max-width: $nameWidth;
      min-width: $nameWidth;
    }

    &--isValue {
      flex-grow: 2;

      @include Info-table-value-normal-font;
    }

    &--isRightNode {
      @include Info-table-value-normal-font;

      text-align: right;
    }

    &--isFlexContainer {
      display: flex;
      flex-wrap: nowrap;
      gap: $u;
    }

    &--isBold {
      font-weight: bold;
    }

    &--isTrigger {
      padding: 0 0 0 2*$u;
      width: 8*$u;
      flex-shrink: 0;
    }

    &--isEmpty {
      color: $gray;
    }
  }
}
