@import "style-guide";

.failed-message {
  font-size: 12px;
  font-weight: bold;
  color: $tomato-red;
  text-align: center;
  max-width: 18*$u;
}

.status {
  color: $gray;

  &--is-warning {
    color: $orange;
  }
}

.time {
  font-size: 18px;
  font-weight: bold;
  white-space: nowrap;
}

.message {
  font-size: 10px;
  font-weight: bold;
  padding: $u 0;
}
