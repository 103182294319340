@import "style-guide";

.content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;

  &__thumbnail {
    width: 20*$u;
    height: 20*$u;
    margin-right: 2*$u;
    margin-bottom: 2*$u;
  }
}

.placeholder {
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  line-height: 1.25;
  color: $charcoal;
  flex-grow: 1;
  text-align: center;
  padding-top: 2*$u;
  padding-bottom: 3*$u;
}

.thumbnail {
  position: relative;
  border-radius: $controlDefaultBorderRadius;
  background-color: $light-30-percent;
  cursor: pointer;

  &__img {
    width: 100%;
    height: 100%;
    border-radius: $controlDefaultBorderRadius;
    object-fit: cover;
  }

  &__control {
    position: absolute;
    top: $u;
    right: $u;
  }
}

.loader {
  &__container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &--is-paranja {
      background-color: $white-40-percent;
    }
  }

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
