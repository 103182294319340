@import "style-guide";

.wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.scroll {
  height: 100%;
  overflow-y: scroll;

  &--hasStickyHeader {
    padding-top: $minHeaderHeight;
  }
}

.content {
  position: relative;
  padding-bottom: 10 * $u;
}
