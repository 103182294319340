@import "style-guide";

.action {
  margin-right: 2*$u;

  &:last-child {
    margin-right: 0;
  }
}

.actions {

}
