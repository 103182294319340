@import "style-guide";

.container {}

.route {
  margin-bottom: 6*$u;
}

.alert {
  margin-bottom: 2*$u;
}

$directionWidth: 2*$u;

.table {
  margin-bottom: 4*$u;

  &--origin,
  &--destination {
    margin-bottom: 2 * $u;
  }
}

.spot-request-row {
  margin-bottom: 2 * $u;
}

.excluded-countries {
  margin-bottom: 5*$u;
}

.team-driver {
  margin-right: $u;
}

.pill {
  margin-right: $u;
}

.info-table-row {
  &--origin,
  &--destination {
    margin-bottom: 3*$u;
  }
}

.papers-card {
  margin-top: 4*$u;
}

.route-card {
  margin-bottom: 4*$u;
}

.shipment-card {
  margin-bottom: 2*$u;
}
