@import "style-guide";

.option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.model {
  @include Dropdown-ListBold-font;
}

.plate-number {
  @include Dropdown-ListBold-font;
}

.details-trigger {
  padding-right: $u;
}
