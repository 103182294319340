@import "style-guide";

.container {}

.route {
  margin-bottom: 6*$u;
}

.table {
  margin-bottom: 4*$u;
}

.route-card {
  margin-bottom: 4*$u;
}

.excluded-countries {
  margin-bottom: 5*$u;
}

.row {
  &--price {
    margin-bottom: 2*$u;
  }
}

.bids {
  margin-bottom: 4*$u;
}
