@import "style-guide";

$scaleHeight: 4*$u;

.dnd-wrap {
  position: absolute;
  bottom: 0;
  z-index: $z-index-range-selector-2;
  width: 100%;
  height: $scaleHeight;
  text-align: center;
}

.icon {
  display: inline-block;

  &--is-hide {
    opacity: 0;
  }
}
