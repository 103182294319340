@import "style-guide";

.dropdown {

}

.input {
  position: relative;
}

$borderWidth: 2px;

.trigger {
  position: relative;
}
