@import "style-guide";

.options {
  max-height: 40*$u;
  overflow-y: auto;
}

.overlay {
  min-width: 100%;
}

.option {
  cursor: pointer;

  @include Dropdown-ListBold-font;

  &:hover {
    background-color: $optionHoverBackgroundColor;
  }

  &--isSelected {
    background-color: $inputHoverBackgroundColor;

    &:hover {
      background-color: $inputHoverBackgroundColor;
    }
  }

  &--hasSeparator {
    border-bottom: 1px solid $light;

    &:last-child {
      border-bottom: none;
    }
  }
}
