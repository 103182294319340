@import "style-guide";

.wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: $z-index-form-top-bar;
  background-color: $white;

  &--hasBottomBorder {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  &--hasBottomShadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
  }
}
