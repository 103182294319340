@import "style-guide";

.toast {
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);
  transition: transform $defaultAnimationDuration;
  transform: translateX(100%);

  &--is-show {
    transform: translateX(0%);
  }
}
