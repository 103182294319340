@import "style-guide";

.timeline {
  position: relative;
  height: 100%;
  overflow-y: auto;
}

.inner {

}

.delimiter {
  position: absolute;
  width: 100%;
  height: 0;

  &--step {
    border-bottom: solid 1px $slate;
  }

  &--halfStep {
    border-bottom: dashed 1px $slate;
  }

  &--now {
    border-bottom: dashed 1px $brand-accent;
  }

  &__text {
    position: absolute;
    bottom: 0;

    @include Timeline-font;

    margin-left: $u;
    margin-bottom: $u;

    &--now {
      color: $brand-accent;
    }
  }
}

.now {
  display: flex;

  &__text {
    margin-right: $u;
  }
}

.level {
  position: absolute;

  // left & width in js
  top: 0;
  bottom: 0;
}

.time-window {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $u 0;
  border-radius: 2*$u;
  cursor: pointer;
  user-select: none;

  &--past {
    background-color: #4b4c4f;

    &:hover {
      background-color: #5d5e61;
    }
  }

  &--future {
    background-color: $slate;

    &:hover {
      background-color: #7a7b7d;
    }
  }

  &--unavailable {
    background-color: $orange;

    &:hover {
      background-color: #ed9d19;
    }
  }

  &--in-progress {
    background-color: $brand-accent;

    &:hover {
      background-color: #96c143;
    }
  }

  &--accent {
    background-color: $light;

    &:hover {
      background-color: #e5e8ef;
    }
  }

  &--isTransparent {
    opacity: 0.5;
  }

  &__icon {

  }

  &__label-wrap {
    width: 4*$u;
  }

  &__label {
    @include TimeWindow-font;

    line-height: 19px;
    writing-mode: vertical-lr;
    text-orientation: mixed;

    &--past {
      color: $gray;
    }

    &--accent {
      color: $charcoal;
    }
  }
}
