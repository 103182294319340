@import "style-guide";

.ReactDayPicker {
  display: inline-block;

  @include Calendar-font;

  &__wrapper {
    position: relative;
    flex-direction: row;
    padding: 0;
    outline: none;
    user-select: none;
  }

  &__NavBar {
  }

  &__NavButton {
    position: absolute;
    top: 0;
    right: 0;
    left: auto;
    display: inline-block;
    width: 4*$u;
    height: 4*$u;
    background-image: url("../../../common/images/calendar-icon-20-px-icn-arrow-back.svg");
    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;
    color: $gray;
    cursor: pointer;
    outline: none;

    &:hover {
      opacity: 0.5;
    }

    &--prev {
      margin-right: 6 * $u;
    }

    &--next {
      transform: rotate(180deg) translateY(0.5px);
    }

    &--interactionDisabled {
      display: none;
    }
  }

  &__Months {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
  }

  &__Month {
    display: table;
    margin: 0 2*$u 0 0;
    border-spacing: 0;
    border-collapse: collapse;
    user-select: none;

    &:last-child {
      margin-right: 0;
    }
  }

  &__Caption {
    display: table-caption;

    @include Calendar-font;

    margin-bottom: 4 * $u;
    margin-left: $u;
    text-align: left;
  }

  &__Weekdays {
    display: table-header-group;
  }

  &__WeekdaysRow {
    display: table-row;
  }

  &__Weekday {
    display: table-cell;
    padding-bottom: 2*$u;
    text-align: center;
    text-transform: uppercase;

    @include Calendar-weekday-font;

    abbr[title] {
      border-bottom: none;
      text-decoration: none;
    }
  }

  &__Body {
    display: table-row-group;
  }

  &__Week {
    display: table-row;
  }

  $dayBoxSize: 34px;
  $layoutBoxSize: 6*$u;

  &__Day {
    position: relative;
    display: table-cell;
    min-width: $dayBoxSize;
    max-width: $dayBoxSize;
    width: $dayBoxSize;
    min-height: $dayBoxSize;
    max-height: $dayBoxSize;
    height: $dayBoxSize;
    line-height: $dayBoxSize;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    outline: none;
    user-select: none;
    z-index: 0;

    &::after {
      top: 2px;
      content: "";
      position: absolute;
      width: $layoutBoxSize/2;
      height: $layoutBoxSize;
      background-color: transparent;
      z-index: -1;
    }

    &::before {
      content: "";
      position: absolute;
      width: $layoutBoxSize;
      height: $layoutBoxSize;
      background-color: transparent;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
    }

    &--today {
      font-weight: bold;
    }

    &--outside {
      // color: $gray;
    }

    &--disabled:not(.ReactDayPicker__Day--selected) {
      color: $light;
      cursor: default;

      &::before {
        display: none;
      }

      &::after {
        display: none;
      }
    }

    &:hover:not(.DayPicker__Day--outside):not(.startAndEnd):not(.start):not(.end) {
      &::before {
        width: $layoutBoxSize;
        background-color: $brand-30-percent;
        border-radius: 50%;
      }
    }

    &--selected:not(.DayPicker__Day--outside):not(.startAndEnd):not(.start):not(.end) {
      color: #fff;

      &::before {
        width: $dayBoxSize !important;
        background-color: $brand-30-percent;
      }

      &:last-child {
        &::before {
          border-radius: 0 4px 4px 0;
        }

        &:hover {
          &::before {
            border-radius: 0 4px 4px 0;
          }
        }
      }

      &:first-child {
        &::before {
          border-radius: 4px 0 0 4px;
        }

        &:hover {
          &::before {
            border-radius: 4px 0 0 4px;
          }
        }
      }

      &:hover {
        &::before {
          border-radius: 0;
        }
      }

      &.ReactDayPicker__Day--disabled {
        &::before {
          background: $tomato-red;
        }
      }
    }
  }

  &__WeekNumber {
    display: table-cell;
    padding: $u;
    min-width: 4*$u;
    border-right: 1px solid $light;
    color: $gray;
    vertical-align: middle;
    text-align: right;
    cursor: pointer;
  }

  &__Footer {
    padding-top: $u;
  }
}

.start {
  color: $white;

  &::before {
    border-radius: 50%;
    background-color: $brand-accent;
  }

  &::after {
    right: 0;
    background-color: $brand-30-percent;
  }

  &:last-child {
    &::after {
      border-radius: 0 4px 4px 0;
    }

    &:hover {
      &::after {
        border-radius: 0 4px 4px 0;
      }
    }
  }

  &:first-child {
    &::after {
      border-radius: 4px 0 0 4px;
    }

    &:hover {
      &::after {
        border-radius: 4px 0 0 4px;
      }
    }
  }
}

.end {
  color: $white;

  &::before {
    border-radius: 50%;
    background-color: $brand-accent;
  }

  &::after {
    left: 0;
    background-color: $brand-30-percent;
  }

  &:last-child {
    &::after {
      border-radius: 0 4px 4px 0;
    }

    &:hover {
      &::after {
        border-radius: 0 4px 4px 0;
      }
    }
  }

  &:first-child {
    &::after {
      border-radius: 4px 0 0 4px;
    }

    &:hover {
      &::after {
        border-radius: 4px 0 0 4px;
      }
    }
  }
}

.startAndEnd {
  color: $white;

  &::before {
    border-radius: 50%;
    background-color: $brand-accent;
  }

  &::after {
    display: none;
  }
}
