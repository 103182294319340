@import "style-guide";

.wrap {
  display: table-caption;
  margin-bottom: 4 * $u;
  margin-left: $u;
  text-align: left;
}

.select {
  font-size: 11px;
  font-weight: bold;
  line-height: 1.82;
  color: $charcoal;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &--year {

  }

  &--month {
    text-transform: uppercase;
    margin-right: 2 * $u;
  }
}
