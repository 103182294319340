@import "style-guide";

.content {
  max-width: 72*$u;
  margin-bottom: 6*$u;
}

.actions {
  text-align: center;
}

.method {
  word-wrap: break-word;
}

.logs {
  margin-top: 6*$u;
}

.background-error {
  position: fixed;
  bottom: 6*$u;
  right: 6*$u;
  z-index: $z-index-error-panel;
  padding: 6*$u;
  border-radius: 4px;
  background-color: $white;

  @include control-shadow;
}
