@import "style-guide";

.options {
  max-height: 40*$u;
  overflow-y: auto;
}

.search-input-wrap {
  position: relative;
}

$iconSize: 4*$u;
$iconHorizontalPadding: 2*$u;
$iconVerticalPadding: 2*$u;

.icon {
  position: absolute;
  width: $iconSize;
  height: $iconSize;

  &--search {
    right: $iconHorizontalPadding;
    top: $iconVerticalPadding;
  }

  &--right-icon {
    right: $iconSize + 2 * $iconHorizontalPadding;
    top: $iconVerticalPadding;
  }
}

.search-input {
  width: 100%;
  border: solid $inputBorderWidth transparent;

  @include base-font;
  @include Input-Value-Bold-font;

  padding: ((2*$u) - $inputBorderWidth) ((3*$u) - $inputBorderWidth);
  padding-right: 2*$iconHorizontalPadding + $iconSize - $inputBorderWidth;
  border-radius: $controlDefaultBorderRadius;
  background-color: $inputDefaultBackgroundColor;
  outline: none;

  &:hover {
    background-color: $inputHoverBackgroundColor;
  }

  &:focus {
    background-color: $inputFocusBackgroundColor;
  }

  &:placeholder {
    @include Input-Value-Placeholder-font;
  }

  &--isFocus {
    background-color: $inputFocusBackgroundColor;
    border-color: transparent;
  }

  &--isEmpty {
    @include Input-Value-Placeholder-font;
  }
}

$optionVerticalPadding: 2*$u;

.option {
  display: flex;
  align-items: center;
  cursor: pointer;

  @include Dropdown-ListBold-font;

  &:hover {
    background-color: $optionHoverBackgroundColor;
  }

  &__checkbox {
    padding: $optionVerticalPadding 0 $optionVerticalPadding 2*$u;
  }

  &__label {
    flex-grow: 1;
    padding: $optionVerticalPadding 0;
    white-space: nowrap;

    @include Text-overflow-ellipsis;
  }
}

.overlay {
  min-width: 100%;
}

.separator {
  background-color: $light-30-percent;
  height: 2 * $u;
}
