@import "style-guide";

$defaultVerticalPadding: $u;
$defaultHorizontalPadding: $u;

.button {
  position: relative;
  outline: none;
  padding: $defaultVerticalPadding $defaultHorizontalPadding;
  border: none;
  border-radius: $controlDefaultBorderRadius;

  @include base-font;
  @include Transparent-Button-font;

  background-color: transparent;

  &--isClickable {
    cursor: pointer;

    &:hover {
      background-color: $transparent-light-50-percent;
    }

    &:active {
      background-color: $transparent-light-70-percent;
    }
  }
}

.inner {
  white-space: nowrap;
}
