@import "style-guide";

$borderRadius: 3px;
$borderWidth: 1px;

.table {
  position: relative;
  background: transparent;

  table {
    width: 100%;
    border-spacing: 0;
  }

  /* stylelint-disable declaration-block-single-line-max-declarations */

  /* sticky line loader */
  thead tr:last-child td { position: sticky; top: 0; }
  /* stylelint-enable declaration-block-single-line-max-declarations */

  tbody {
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: $borderRadius;

    tr {
      background-color: $white;

      &:hover {
        background-color: $light-5-percent;
      }

      td {
        position: relative;
        border-bottom: $borderWidth solid $light;

        /* сноска слева */
        &:first-child {
          &::after {
            content: " ";
            position: absolute;
            top: 0;
            left: 0;
            width: 3px;
            height: calc(100% + #{$borderWidth});
            background-color: transparent;
          }
        }

        /* сноска справа */
        &:last-child {
          &::after {
            content: " ";
            position: absolute;
            top: 0;
            right: 0;
            width: 3px;
            height: calc(100% + #{$borderWidth});
            background-color: transparent;
          }
        }
      }

      &:first-child {
        td {
          &:first-child {
            border-top-left-radius: $borderRadius;

            /* сноска слева */
            &::after {
              border-top-left-radius: $borderRadius;
            }
          }

          &:last-child {
            border-top-right-radius: $borderRadius;

            /* сноска справа */
            &::after {
              border-top-right-radius: $borderRadius;
            }
          }
        }
      }

      &:last-child {
        td {
          border-bottom: none;

          &:first-child {
            border-bottom-left-radius: $borderRadius;

            /* сноска слева */
            &::after {
              border-bottom-left-radius: $borderRadius;
              height: calc(100%);
            }
          }

          &:last-child {
            border-bottom-right-radius: $borderRadius;

            /* сноска справа */
            &::after {
              border-bottom-right-radius: $borderRadius;
              height: calc(100%);
            }
          }
        }
      }
    }
  }

  .row {
    &--isSelectable {
      cursor: pointer;
    }

    &--hasLeftGreenBorder {
      td {
        /* сноска слева */
        &:first-child {
          &::after {
            background-color: $brand-accent;
          }
        }
      }
    }

    &--hasLeftRedBorder {
      td {
        /* сноска слева */
        &:first-child {
          &::after {
            background-color: $tomato-red;
          }
        }
      }
    }

    &--hasLeftGreyBorder {
      td {
        /* сноска слева */
        &:first-child {
          &::after {
            background-color: $light;
          }
        }
      }
    }

    &--hasLeftOrangeBorder {
      td {
        /* сноска слева */
        &:first-child {
          &::after {
            background-color: $orange;
          }
        }
      }
    }

    &--isHighlighted {
      background-color: #fbfcfd;

      &:hover {
        background-color: #fafafc;
      }
    }

    &--isSelected {
      background-color: #fcfdf9;

      &:hover {
        background-color: #fbfdf7;
      }
    }
  }
}
