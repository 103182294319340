@import "style-guide";

.icon {
  margin-left: auto;
  margin-right: auto;
  width: 34*$u;
  height: 34*$u;
  background-repeat: no-repeat;
  background-position: center;

  &--BOX {
    background-image: url("../../../images/box.svg");
  }

  &--TILT {
    background-image: url("../../../images/tilt.svg");
  }

  &--REEF {
    background-image: url("../../../images/reef.svg");
  }
}
