@import "style-guide";

$border-default-base: grey;

.top-bar {
  &--isSticky {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1;
  }
}

.inner {
  position: relative;

  @media screen and (max-width: 768px) {
    padding-left: $widthMenuTrigger;
  }

  transition: background-color $defaultAnimationDuration, box-shadow $defaultAnimationDuration;

  &--isWhite {
    background-color: $white;

    @include top-bar-shadow;
  }

  &--isSticky {
    @include sticky-content-margins;
  }
}

.menu-trigger {
  display: none;

  @media screen and (max-width: 768px) {
    display: flex;
    position: absolute;
    width: $widthMenuTrigger;
    height: $widthMenuTrigger;
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
