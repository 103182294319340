@import "style-guide";

.submit {
  display: inline-block;
  width: 100%;
}

.field {
  &--date-range {
    max-width: 48*$u;
    margin: 0 auto;
  }
}

.alert {
  margin-bottom: 2*$u;
}

.form {
  margin-bottom: $u;
}
