@import "style-guide";

.input {
  position: relative;
  display: inline-flex;
}

.trigger {
  position: relative;
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  border-radius: $controlDefaultBorderRadius;
  padding: $u;
  user-select: none;
  transition: background-color $fastAnimationDuration;

  &--withoutBorderRadius {
    border-radius: 0;
  }

  &--isClickable {
    cursor: pointer;

    &:hover {
      background-color: $light-50-percent;
    }
  }

  &--isPressed {
    background-color: $inputPressedBackgroundColor;

    &:hover {
      background-color: $inputPressedBackgroundColor;
    }
  }

  &--isActive {
    background-color: $light !important;
  }

  &__icon {
    pointer-events: none;
  }

  &__label {
    @include Input-Value-Normal-font;

    padding-left: $u;
    padding-right: 2*$u;
  }
}

.trigger-icon {
  width: 6*$u;
  height: 6*$u;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("./icons/icn-sort-desc.svg");
  border-radius: 4px;
  pointer-events: auto;

  &:hover {
    background-color: $light;
  }

  &--isRevert {
    transform: rotateX(180deg);
  }
}

.overlay {
  position: absolute;
  top: 100%;
  background: $white;

  @include control-shadow;

  border-radius: $u;
  z-index: $z-index-overlay;
  animation: fadeIn 0.1s ease-in;
  overflow-y: auto;

  &--position-left {
    left: 0;
  }

  &--position-right {
    right: 0;
  }
}

.option {
  position: relative;
  padding: 2*$u 3*$u;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: $optionHoverBackgroundColor;
  }

  &--isSelected {
    background-color: $inputHoverBackgroundColor;
    padding-right: 2*$u;

    &:hover {
      background-color: $inputHoverBackgroundColor;
    }
  }

  &:last-child {
    border-bottom: none;
  }

  &__label {
    @include Dropdown-ListBold-font;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__icon {
    padding-left: 2 * $u;
  }
}

.trigger-tooltip {
  @include TooltipDark-font;

  white-space: nowrap;
  padding: 2*$u;
}

.icon {
  vertical-align: top;
}
