@import "style-guide";

.document-summary {
  margin-bottom: 8 * $u;
}

.tabs {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-bottom: 6 * $u;

  &__tab {
    margin-right: 4*$u;
  }
}

.version {
  margin-bottom: 4*$u;
}

.actual-version-details {
  margin-bottom: 10*$u;
}

.tab-content {
  position: relative;
}
