@import "style-guide";

.wrap {
  position: relative;
  padding-top: 6*$u;
}

.form {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.title {
  @include Title-Widget-font;
}

.head {
  display: flex;

  &__changes {
    margin-right: 10*$u;
  }

  &__save {
    margin-right: 10px;
  }
}

.alert {
  margin-bottom: 6*$u;
}

.field {
  &--country {
    width: 50%;
  }

  &--zip {
    width: 20%;
  }

  &--city {
    width: 30%;
  }
}

.widget {
  margin-bottom: 6*$u;
  width: calc(50% - #{3 * $u});

  @media screen and (max-width: 1200px) {
    width: 100%;
  }

  &__inner {
    box-sizing: border-box;
    padding: 6*$u 6*$u 4*$u 6*$u;
  }
}

.copy {
  &__wrap {
    margin-left: 2*$u;
  }

  margin: 2*$u;
}
