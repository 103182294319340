@import "style-guide";

.table {
  &__header {
    padding: 0 3*$u;

    &--id {
      padding: 0 3*$u 0 4*$u;

      @include table-column-auto-adjust-width;
    }

    &--name {
      flex-grow: 1;
    }

    &--orders-counter {
      padding: 0 4*$u;

      @include table-column-auto-adjust-width;
    }

    &--uploaded {
      padding: 0 4*$u;

      @include table-column-auto-adjust-width;
    }

    &--expire-date {
      padding: 0 4*$u;

      @include table-column-auto-adjust-width;
    }

    &--status {
      @include table-column-auto-adjust-width;

      padding: 0 10*$u 0 3*$u;
    }
  }

  &__column {
    padding: 3*$u;

    &--id {
      padding: 5*$u 3*$u 5*$u 4*$u;

      @include table-column-auto-adjust-width;
    }

    &--name {
      flex-grow: 1;
    }

    &--uploaded {
      padding: 3*$u 4*$u;
      max-width: 50 * $u;

      @include table-column-auto-adjust-width;
    }

    &--orders-counter {
      padding: 3*$u 4*$u;
      text-align: center;

      @include table-column-auto-adjust-width;
    }

    &--expire-date {
      padding: 3*$u 4*$u;

      @include table-column-auto-adjust-width;
    }

    &--status {
      @include table-column-auto-adjust-width;

      padding: 3*$u 10*$u 3*$u 3*$u;
    }
  }
}
