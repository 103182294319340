@import "style-guide";

.textarea {
  display: flex;
  width: 100%;
  padding: ((2*$u) - $inputBorderWidth) ((3*$u) - $inputBorderWidth);
  border-radius: $controlDefaultBorderRadius;
  background-color: $inputDefaultBackgroundColor;
  border: solid $inputBorderWidth transparent;

  &--isTransparent {
    background-color: transparent;
    border-color: transparent;
  }

  &--isHovered {
    background-color: $inputHoverBackgroundColor;
  }

  &--isFocused {
    background-color: $inputFocusBackgroundColor;
  }

  &--isEmpty {
    border-color: transparent;
  }

  &--hasSuccess {
    border-color: $inputSuccessBorderColor;
  }

  &--hasWarning {
    border-color: $inputWarningBorderColor;
  }

  &--hasChanges {
    border-color: $inputWarningBorderColor;
  }

  &--isHighlighted {
    border-color: $inputWarningBorderColor;
  }

  &--hasError {
    border-color: $inputErrorBorderColor;
  }

  &--isDisabled {
    background-color: $inputDisabledBackgroundColor;
    cursor: not-allowed;

    &:hover {
      background-color: $inputDisabledBackgroundColor;
    }
  }
}

.inner-textarea {
  outline: none;

  @include base-font;
  @include Input-Value-Bold-font;

  &:placeholder {
    @include Input-Value-Placeholder-font;
  }

  &--isEmpty {
    @include Input-Value-Placeholder-font;
  }

  &--isDisabled {
    color: $inputDisabledColor;
  }
}
