@import "style-guide";

.option {
  padding-left: $u;
}

.contract-name {
  max-width: 50*$u;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: $u;
}
