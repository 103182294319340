@import "style-guide";

.content {
  text-align: center;
}

.error {
  text-align: center;
  color: $tomato-red;
}
