@import "style-guide";

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: $z-index-sticky-footer;
  pointer-events: none;

  /* start fix: add scroll width */
  overflow-y: scroll;
  overflow-x: hidden;

  /* end fix: add scroll width */
}

.container {
  position: relative;
  pointer-events: none;
}

.content {
  display: inline-block;
  pointer-events: auto;
}
