@import "style-guide";

.select {
  display: flex;
  justify-content: center;

  &__option {

  }
}

.option {
  cursor: pointer;
  flex-grow: 1;
  max-width: 32*$u;
  width: 100%;
  margin: 0 3*$u;

  &__image {
    width: 100%;
    padding-top: 100%; /* ratio 1:1 */
    margin-bottom: 4*$u;
    filter: grayscale(1);
    opacity: 0.5;
    transition: filter 0.3s, opacity 0.3s;
    background-size: contain;
    background-repeat: no-repeat;

    &--box {
      background-image: url("../../images/box.svg");
    }

    &--tilt {
      background-image: url("../../images/tilt.svg");
    }

    &--reef {
      background-image: url("../../images/reef.svg");
    }

    &--isActive {
      filter: grayscale(0);
      opacity: 1;
    }
  }

  &__title {
    text-align: center;
  }
}

.radio {
  justify-content: center;
}
