@import "style-guide";

.content {
  position: relative;
  padding: 9*$u 4*$u 4*$u 6*$u;
}

.close-button {
  position: absolute;
  top: 4*$u;
  right: 4*$u;
}
