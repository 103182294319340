@import "style-guide";

.trigger {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &__country {
    margin-right: $u;
  }
}

.country {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: $u;
  border-radius: 4px;

  &--isClickable {
    cursor: pointer;

    &:hover {
      background-color: $light-50-percent;
    }
  }

  &--isSelected {
    background-color: $light;
  }
}

.input {
  flex-grow: 1;
}

.prefix {
  @include base-font;
  @include Input-Value-Bold-font;

  &--isDisabled {
    color: $inputDisabledColor;
  }
}

.inner-input {
  width: 100%;
  border: none;
  background-color: transparent;
  outline: none;
  flex-grow: 1;
  min-width: 0;
  padding: 0;

  @include base-font;
  @include Input-Value-Bold-font;

  &--isDisabled {
    color: $inputDisabledColor;
  }
}
