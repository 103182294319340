@import "style-guide";

.wrap {
}

.title {
  @include secondary-font;
  @include Title-Widget-font;

  margin-bottom: 8*$u;
}

.form {
  padding-bottom: 5*$u;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2*$u;
}

.sub-actions {
  width: 60%;
  display: flex;
  flex-direction: row;
  gap: 2*$u;
}

.action {
  &--clear-assets {
    width: 25%;
  }

  &--reassign-cancel {
    width: 40%;
  }

  &--reassign {
    width: 60%;
  }

  &--assign-cancel {
    width: 30%;
  }

  &--assign {
    width: 70%;
  }
}
