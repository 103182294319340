@import "style-guide";

$defaultVerticalPadding: 4*$u;
$defaultHorizontalPadding: 6*$u;

.wrap {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $z-index-form-bottom-bar;

  &--hasTopBorder {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }
}

.inner {
  &--isAbsolute {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.content {
  &--hasPaddings {
    padding: 0 $defaultHorizontalPadding $defaultVerticalPadding $defaultHorizontalPadding;
  }

  &--hasBackground {
    padding-top: $defaultVerticalPadding;
    background-color: $white;
  }

  &--hasShadow {
    border-radius: 4px;
    box-shadow: 0 1px 3*$u 0 rgba(0, 0, 0, 0.1);
    background-color: $white;
    margin-left: 3*$u; // left shadow
  }
}
