@import "style-guide";
@import "auth";

.submit {
  @include submit-button;
}

.footer-links {
  @include footer-links;

  &__link {
    @include footer-link;
  }
}

.input {
  padding-bottom: 2*$u;
}
