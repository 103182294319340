@import "style-guide";

.button {
  width: 100%;
}

.form {
  height: 100%;
}

.block {
  margin-bottom: 10*$u;

  &__title {
    @include Title-Widget-font;

    margin-bottom: 6*$u;
  }
}

.time-picker {
  margin-bottom: 6*$u;
}

.trailer-type {
  margin-bottom: 2*$u;

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: $charcoal;
  }
}
