@import "style-guide";

.upload-pseudo-row {
  text-transform: uppercase;
  width: 100%;
}

.upload-text {
  display: inline-block;
  padding: 3*$u 0;
  font-size: 11px;
  line-height: 1.82;
  font-weight: bold;
}

.navigation-tabs {
  margin-bottom: 6*$u;
}
