@import "style-guide";

.button {
  position: relative;
  outline: none;
  padding: 0;
  user-select: none;

  @include Input-Value-Normal-font;
  @include base-font;

  &--isClickable {
    cursor: pointer;
  }

  &--isDisabled {
    opacity: 0.2;
    color: $white;
  }

  &--height-regular {
    padding: $u 0;
  }

  &--height-narrow {
    padding: 0;
  }
}

.icon {
  display: inline-block;
  vertical-align: top;
  height: 4*$u;

  &--isLeft {
    padding-right: $u;
  }

  &--isRight {
    padding-left: $u;
  }

  &--isHidden {
    opacity: 0;
  }
}

.text {
  position: relative;
  display: inline-block;
  vertical-align: top;
  font-size: 10px;
  font-weight: bold;
  line-height: 2;
  letter-spacing: 0.4px;
  text-transform: uppercase;

  &--theme-brand-dark {
    color: $brand-dark;
  }

  &--isHidden {
    opacity: 0;
  }
}

$borderHeight: 1px;

.border {
  position: absolute;
  left: 0;
  right: 0;
  height: $borderHeight;
  border-radius: $borderHeight;

  &--theme-brand-dark {
    background-color: $brand-dark;
  }

  &--height-regular {
    bottom: -1 * ($u - $borderHeight);
  }

  &--height-narrow {
    bottom: 0;
  }
}

.inner {
  white-space: nowrap;
}
