@import "style-guide";

.notification {
  display: flex;
  align-items: center;

  &__icon {
    margin-right: 2*$u;
    position: relative;
    top: -1px;
  }

  &__label {
    @include Status-Regular-font;
  }
}

.circle {
  width: 2*$u;
  min-width: 2*$u;
  height: 2*$u;
  border: 3px solid grey;
  border-radius: 50%;

  &__orange {
    border-color: $squash;
  }

  &__green {
    border-color: $medium-green;
  }
}
