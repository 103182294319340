@import "style-guide";

.wrap {
  width: 68*$u;
}

.button {
  width: 100%;
}

.title {
  @include TitleEmpty-State-font;

  text-align: center;
  margin-bottom: 6*$u;
}

.image {
  height: 130px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../../images/illustration-plus.svg");
  margin-bottom: 2*$u;
}

.form {
  padding-bottom: 2*$u;
}
