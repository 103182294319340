@import "style-guide";

$activeBorderWidth: 3px;
$activeBorderColor: $brand-30-percent;
$selectedBorderColor: $brand-accent;

.tab {
  cursor: pointer;
  position: relative;
  padding: 5*$u 0 5*$u;

  &--size-normal {
    @include Tab-Normal-font;

    margin-right: 6*$u;
  }

  &--size-small {
    @include Tab-Small-font;

    margin-right: 4*$u;
  }

  &--isCompact {
    padding: 2*$u 0 2*$u;
  }

  &--theme {
    &-dark {
      color: $light;
    }

    &-light {
      color: $slate;
    }
  }

  &::after {
    content: " ";
    position: absolute;
    width: 100%;
    height: $activeBorderWidth;
    bottom: 0;
    left: 0;
    transition: background-color $defaultAnimationDuration, opacity $defaultAnimationDuration;
    opacity: 0;
    background-color: $activeBorderColor;
  }

  &:hover {
    &.tab--theme {
      &-light {
        color: $charcoal;
      }
    }

    &::after {
      opacity: 1;
      background-color: $activeBorderColor;
    }
  }

  &--isActive {
    @include bold-font-for-avoid-jumping($slate);

    &.tab--theme {
      &-light {
        @include bold-font-for-avoid-jumping($charcoal);

        color: $charcoal;
      }

      &-dark {
        @include bold-font-for-avoid-jumping($white);

        color: $white;
      }
    }

    &::after {
      opacity: 1;
      background-color: $selectedBorderColor;
    }

    &:hover {
      &::after {
        opacity: 1;
        background-color: $selectedBorderColor;
      }
    }
  }
}

.inner {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__badge {
    margin-right: 2*$u;
    max-height: 4*$u;
  }

  &__content {
    line-height: 4*$u;
  }
}
