@import "style-guide";

.table {
  &__header {
    @include Table-Header-font;
    @include Table-Header-middle-column;

    text-align: left;
  }

  &__column {
    &--avatar {
      width: 6*$u;
      padding: 4*$u 0 4*$u 4*$u;
    }

    &--any {
      @include Table-Row-middle-column;
    }

    &--status {
      @include table-column-auto-adjust-width;

      padding: 4*$u 7*$u 4*$u 2 * $u;
    }
  }
}
