@import "style-guide";

@mixin Filter-pill-font {
  font-size: 12px;
  font-weight: bold;
  line-height: 4*$u;
}

$compactIconSize: 4*$u;
$iconSize: 6*$u;

.pill {
  position: relative;
  display: inline-block;
  white-space: nowrap;
  vertical-align: top;

  @include Filter-pill-font;

  border-radius: 4px;
  user-select: none;

  &--has-icon {
    margin-left: $iconSize/2;
  }

  &--is-compact.pill--has-icon {
    margin-left: $compactIconSize/2;
  }

  &--is-full-clickable {
    cursor: pointer;

    &:hover {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0.1;
        background-color: $white;
      }
    }
  }

  &--theme {
    &-brandDark {
      color: $white;
      background-color: $brand-dark;
    }

    &-charcoal {
      color: $white;
      background-color: $charcoal;
    }

    &-brandAccent {
      color: $white;
      background-color: $brand-accent;
    }

    &-blazeOrange {
      color: $white;
      background-color: $blaze-orange;
    }

    &-tomatoRed {
      color: $white;
      background-color: $tomato-red;
    }

    &-attention {
      color: $white;
      background-color: $orange;
    }
  }
}

.icon {
  position: absolute;
  left: -$iconSize/2;
  top: 0;
  width: $iconSize;
  height: $iconSize;

  &--is-compact {
    left: -$compactIconSize/2;
    width: $compactIconSize;
    height: $compactIconSize;
  }
}

.icon-placeholder {
  display: inline-block;
  vertical-align: top;
  width: $iconSize/2;

  &--is-compact {
    width: $compactIconSize/2;
  }
}

.label {
  display: inline-block;
  vertical-align: top;
  padding-left: 2*$u;
  padding-right: 2*$u;
  padding-top: $u;
  padding-bottom: $u;

  &--is-compact {
    padding-left: $u;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.closer {
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  color: $white-40-percent;
  padding: $u;

  &--is-compact {
    padding: 0;
  }

  &:hover {
    color: $white;
  }
}
