@import "style-guide";

.action {
  text-transform: uppercase;
  font-size: 11px;
  line-height: 1.82;
}

.date {
  @include Table-Row-font;
}

.without-expiry {
  @include Table-Row-font;
}
