@import "style-guide";

.avatar {
  display: inline-block;
  vertical-align: top;
  background-color: $light-50-percent;
  background-size: 100% 100%;
  border-radius: 50%;

  &--0 {
    background-image: url("./icons/avatar-face-7.png");

    @media (min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background-image: url("./icons/avatar-face-7@3x.png");
    }
  }

  &--1 {
    background-image: url("./icons/avatar-face-1.png");

    @media (min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background-image: url("./icons/avatar-face-1@3x.png");
    }
  }

  &--2 {
    background-image: url("./icons/avatar-face-2.png");

    @media (min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background-image: url("./icons/avatar-face-2@3x.png");
    }
  }

  &--3 {
    background-image: url("./icons/avatar-face-3.png");

    @media (min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background-image: url("./icons/avatar-face-3@3x.png");
    }
  }

  &--4 {
    background-image: url("./icons/avatar-face-4.png");

    @media (min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background-image: url("./icons/avatar-face-4@3x.png");
    }
  }

  &--5 {
    background-image: url("./icons/avatar-face-5.png");

    @media (min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background-image: url("./icons/avatar-face-5@3x.png");
    }
  }

  &--6 {
    background-image: url("./icons/avatar-face-6.png");

    @media (min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background-image: url("./icons/avatar-face-6@3x.png");
    }
  }

  &--size {
    &-small {
      min-width: 4*$u;
      width: 4*$u;
      height: 4*$u;
    }

    &-medium {
      min-width: 6*$u;
      width: 6*$u;
      height: 6*$u;
    }

    &-large {
      min-width: 8*$u;
      width: 8*$u;
      height: 8*$u;
    }

    &-extra-large {
      min-width: 20*$u;
      width: 20*$u;
      height: 20*$u;
    }
  }
}
