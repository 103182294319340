@import "style-guide";

.input {
  display: flex;
  width: 100%;
  padding: ((2*$u) - $inputBorderWidth) ((3*$u) - $inputBorderWidth);
  border-radius: $controlDefaultBorderRadius;
  background-color: $inputDefaultBackgroundColor;
  border: solid $inputBorderWidth transparent;

  &--isTransparent {
    background-color: transparent;
    border-color: transparent;
  }

  &--isHovered {
    background-color: $inputHoverBackgroundColor;
  }

  &--isFocused {
    background-color: $inputFocusBackgroundColor;
  }

  &--isEmpty {
    border-color: transparent;
  }

  &--hasSuccess {
    border-color: $inputSuccessBorderColor;
  }

  &--hasWarning {
    border-color: $inputWarningBorderColor;
  }

  &--hasChanges {
    border-color: $inputWarningBorderColor;
  }

  &--isHighlighted {
    border-color: $inputWarningBorderColor;
  }

  &--hasError {
    border-color: $inputErrorBorderColor;
  }

  &--isDisabled {
    background-color: $inputDisabledBackgroundColor;

    &:hover {
      background-color: $inputDisabledBackgroundColor;
      cursor: not-allowed;
    }
  }

  &--hasLeftIcon {
    padding-left: (2 * $u) - $inputBorderWidth;
  }

  &--hasRightNode {
    padding-right: $u - $inputBorderWidth;
  }
}

.inner-input {
  border: none;
  background-color: transparent;
  outline: none;
  flex-grow: 1;
  min-width: 0;
  padding: 0;

  @include base-font;
  @include Input-Value-Bold-font;

  &:placeholder {
    @include Input-Value-Placeholder-font;
  }

  &--isEmpty {
    @include Input-Value-Placeholder-font;
  }

  &--isDisabled {
    color: $inputDisabledColor;
    cursor: not-allowed;
  }
}

.icon {
  width: 4*$u;
  height: 4*$u;

  &--isLeft {
    margin-right: 2*$u;
  }

  &--isRight {

  }
}

.right-node {
  margin-left: $u;
  margin-right: $u;

  @include Input-Units-font;

  &--isEmpty {
    @include Input-Value-Placeholder-font;
  }
}

.control {
  margin-right: $u;
  width: 4*$u;
  height: 4*$u;

  &--isClickable {
    cursor: pointer;
    color: $gray;

    &:hover {
      color: $brand-dark;
    }
  }
}
